import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { useObserver } from "mobx-react-lite";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Popover } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import useStyles from './NonAccordianStyles';
import { IUseMux, useGetState } from '../filter/UseAccordionHook';
import { putAsync, IChan } from 'csp-with-ts';
import downArrowIcon from '../../assets/images/ArrowDown.svg';
import sortAscIcon from '../../assets/images/sort/Sort A-z-blue.svg';
import sortDesIcon from '../../assets/images/sort//Sort Z-a-blue.svg';
import upArrowIcon from '../../assets/images/ArrowUp.svg'
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { useStore } from '../../models/ProvideModel';
import downArrow from "../../assets/images/downFilled-arraow.svg"
import "../accordion/Accordion.scss";
import { Modal, ModalOverlay, ModalBody, ModalContent, ModalProps, useModal } from "../../components/modal";

export interface INonAccordianProps<T extends Record<string, any>, K extends keyof T> {
  children?: ReactNode;
  toggle: IChan<boolean>;
  relay: IChan<IUseMux<T, K>>;
  title: string;
  broadcast: IChan<T[K][], T[K][]>;
  sortSelected?: string | null | undefined;
  dateRangeSelect?: string[] | null;
  externalDisplay?: JSX.Element;
  formData?: boolean
};
function NonAccordianSelect<T extends Record<string, any>, K extends keyof T>({ title, children, toggle: toggleCh, relay, broadcast, sortSelected, dateRangeSelect, externalDisplay, formData }: INonAccordianProps<T, K>) {
  const classes = useStyles({ sortSelected });
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const toggle = useGetState<boolean>(toggleCh);
  const close = useCallback(() => { putAsync(relay, { type: 'toggle', payload: false }); }, []);
  const reverse = useCallback(() => { putAsync(relay, { type: 'toggle' }); }, []);
  const [selected, setselected] = useState<string>("")
  const selectState = useGetState(broadcast);
  const [modalState, setModalState] = React.useState(false);
  const store = useStore();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  const SortSelectedIcon = () => {
    switch (sortSelected) {
      case "ascending": return <img src={sortAscIcon} className={classes.sortIcon} alt={"des"} />
      // <span>Sort Applied</span>
      case "ascending_pre": return <img src={sortAscIcon} className={classes.sortIcon} alt={"des"} />
      case "descending": return <img src={sortDesIcon} className={classes.sortIcon} alt={"des"} />
      case "descending_pre": return <img src={sortDesIcon} className={classes.sortIcon} alt={"des"} />
      default: return null
    }
  }

  useEffect(() => {
    if (selectState) {
      if (selectState.length > 0) {
        setselected(`${selectState.length.toLocaleString('en-GB', { minimumIntegerDigits: 2 })} selected`);
      } else if ((sortSelected === "ascending" || sortSelected === "descending" || sortSelected === "ascending_pre" || sortSelected === "descending_pre")) {
        setselected("Sort Applied");
      } else {
        setselected("")
      }
    } else {
      setselected("")
    }
  }, [selectState])

  const onDropdownClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    reverse()
    if (store.responsiveUtils.currentViewport.isLg == false) {
      store.responsiveUtils.openFatDropdown();
    }
  }

  const onOutSideClicked = (event) => {
    /**
     * to prevent touch event on mobile.
     */
    event.preventDefault();
    close();
    if (store.responsiveUtils.currentViewport.isLg === false) {
      // TODO: Remove this hack as soon as possible.
      setTimeout(() => store.responsiveUtils.closeFatDropdown());
    }
  }

  const mobilefullScreenMode = (fullScreenMode) => {
    return !fullScreenMode ? classes.popperInnerContainerSm : classes.popperInnerContainerFs
  }

  return useObserver(() => {
    return (
      <div>
        <div className={classes.root}>
          <Typography variant="button" display={"block"} onClick={onDropdownClicked}
            ref={anchorRef} className={classes.typeButton}>
            {formData ? <div className="formData__section"> <button className={`dropDown flexBox`} style={{ height: "100%" }}>
              <div className="accordion__div">
                <div className={"form_title"} >{title}
                  {
                    (selected ? <div className="TextFont">({selected})</div> : null)
                  }
                </div>
                <img height={10} width={10} src={downArrow} alt='arrow' style={{ transform: !toggle ? "unset" : "rotate(180deg)" }}></img>
              </div>
            </button></div> : externalDisplay ?
              <div className={classes.div}>
                <div className={classes.tabNameMainDiv}>
                  <span style={{ fontSize: store.responsiveUtils.currentViewport.isLg ? "10px" : "8px" }}>{title}</span>
                  <div className={classes.tabnameDiv}>
                    <span className={classes.titleDiv}>{externalDisplay}</span>
                    <img height={30} width={30} src={toggle ? upArrowIcon : downArrowIcon} alt='arrow'></img>
                  </div>
                </div>
              </div>
              : <TextField
                className={classes.selectInput}
                id={`standard-adornment-columnFilter-${title}`}
                label={title}
                value={dateRangeSelect ? dateRangeSelect?.join(' - ') : externalDisplay ? externalDisplay : selected}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img className={classes.arrowImg} src={toggle ? upArrowIcon : downArrowIcon} alt='arrow'></img>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    sortSelected && sortSelected ?
                      <InputAdornment position="start" style={{ cursor: "pointer" }}>
                        <SortSelectedIcon />
                      </InputAdornment> : null
                  ),
                  readOnly: true
                }}
              />
            }
          </Typography>

          <Popper open={!!toggle} anchorEl={anchorRef.current}
            disablePortal
            transition
            placement={"bottom-start"}
            className={store.responsiveUtils.currentViewport.isLg ? classes.popperOuterContainer : classes.popperOuterContainerSm}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
              >
                <Paper className={store.responsiveUtils.currentViewport.isLg ? classes.popperInnerContainer : mobilefullScreenMode(store.responsiveUtils.fullScreenMode)}>
                  <ClickAwayListener onClickAway={onOutSideClicked}>
                    <div>{children}</div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    )
  });
}

export default memo(NonAccordianSelect)