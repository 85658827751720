import { types, flow, getRoot, getSnapshot, Instance } from 'mobx-state-tree';
// import { Instance } from 'mobx-state-tree/dist/internal';
import { LateStoreModel } from './DataStore';
import { GET_USER_GROUP_REPORT_DETAILS } from '../utils/queries';

// type defs
const powerBiConfigModel = types.model({
    group_id: types.string,
    report_id: types.string
})

const userReports = types.model({
    dashboardName: types.string,
    embedConfig: powerBiConfigModel,
    provider: types.string,
    group: types.array(types.string),
})

const activityManagerConfigModel = types.model({
    redirectUrl: types.string
})

const activityManagerReport = types.model({
    dashboardName: types.string,
    embedConfig: activityManagerConfigModel,
    provider: types.string,
    group: types.array(types.string),
})

const UserReportDetails = types.model({
    powerbiDetails: types.array(userReports),
    activityManagerDetails: types.array(activityManagerReport),
}).volatile(_ => ({ loading: false }))
    .views(self => ({
        get shouldRenderPropTool(): boolean {
            return !!self.powerbiDetails || !!self.activityManagerDetails;
        }
    }))
    .actions(self => ({
        clearUserReportDetails () {self.powerbiDetails.clear(); self.activityManagerDetails.clear();},
        getUserReportDetails: flow(function* populate() {
            
            self.loading = true;
            try {
                const root = getRoot(self) as Instance<typeof LateStoreModel>;
                const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_USER_GROUP_REPORT_DETAILS, {
                    variables: { project: root.projectInfo.currentProject?.id },
                    fetchPolicy: 'network-only'
                });
                data?.reports?.forEach(({ provider, dashboardName, embedConfig, group }) => {
                    if (provider === "power-bi")
                        self.powerbiDetails.push({ provider, dashboardName, embedConfig, group })
                    if (provider === "activity-manager")
                        self.activityManagerDetails.push({ provider, dashboardName, embedConfig, group })
                })
            } catch (err) {
                console.error(err.message);
            }
            finally {
                self.loading = false;
            }
        })
    }))

// *exports
export default UserReportDetails;
export type IUserReportDetails = typeof UserReportDetails.Type;
