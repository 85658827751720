import * as React from 'react';
import { Button, makeStyles, CircularProgress, LinearProgress } from '@material-ui/core';
import { useStore } from '../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import { memo } from 'react';
import { GET_ACT_BY_BLOCK_BY_UNIT, GET_SNAGS, GET_UNITS_BY_BLOCK, GET_UNIT_INFO } from '../utils/queries';

export interface IDownloadExcelProps {
  columns: any,
  title: string,
  dataLoading: boolean,
  zeroRows?: boolean,
  getDataForExcel: () => Promise<any>
}


const useStyles = makeStyles(theme => ({
  button: {
    margin: "0px 0px 9px 8px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "96%",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold"
    },
    '@media print': {
      display: "none"
    }
  },
  buttonSm: {
    margin: "0px 0px 0px 7px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold"
    },
    '@media print': {
      display: "none"
    }
  },
  loading: {
    margin: theme.spacing(1),
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    background: "#f4f4f4",
    color: "#585858",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize"
  },
  colorPrimary: {
    backgroundColor: '#f4f4f4',
  },
  barColorPrimary: {
    backgroundColor: '#005EFF',
  },
  download: {
    margin: theme.spacing(1),
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#18bc9c",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "130px",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold",
    },
    '@media print': {
      display: "none"
    },
  },
}));

const DownloadExcel = (props: IDownloadExcelProps) => {

  const classes = useStyles();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);

  const HEADERS: any = (store.params.section === "snags" || store.params.section === "tasks") ? GET_SNAGS :
    (store.params.screen === "fullscreen" && store.params.phase === "finishing") ?
      store.params.section === "legacy" ?
        GET_UNITS_BY_BLOCK : GET_UNIT_INFO : store.params.screen === "misc" ? GET_ACT_BY_BLOCK_BY_UNIT : GET_UNIT_INFO;

  const smMode = (isSm) => {
    return isSm ? "26%" : "22%"
  }

  return useObserver(() =>
    <p style={{ marginLeft: "8px" }}>
      {!store.responsiveUtils.fullScreenMode && <Button
        variant="contained"
        color="primary"
        size="small"
        className={store.responsiveUtils.currentViewport.isLg ? classes.button : classes.buttonSm}
        disabled={store.exportTableFilters.ZeroRows}
        onClick={() => store.params.section === "form-data" ? store.exportTableFilters.thinExcelExport() : store.params.graphType === "table" && store.params.section === "legacy" ? store.exportTableFilters.getunitexceldataPage(HEADERS) : store.exportTableFilters.getexceldataPage(HEADERS)}>
        {(store.exportTableFilters.loading || store.exportTableFilters.ZeroRows) ? null : <img src={require('../assets/images/downloadIcons/download2-small.png')} width={15} height={15} style={{ marginRight: 5 }} />}
        {store.exportTableFilters.loading ? <div className={classes.loading}>GENERATING.. <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /></div> : store.exportTableFilters.title}
      </Button>}
    </p>
  )
}

export default memo(DownloadExcel);
