import { Instance, types, SnapshotOrInstance, getEnv, getRoot } from 'mobx-state-tree';
import moment, { Moment, MomentBuiltinFormat } from 'moment';
import { CellAddress } from './CellAddress';
import { DateDiff, eProgressTypes, eScreen, ProgressMeasure, IdDateDiff, DateType, eStatus } from '../Common';
import { LateStoreModel } from '../DataStore';
import { BrickTypes, CellAddressType } from '../enums';
import { IHardcoded, __hardcoded } from '../../utils/constants';

const ProgressDetails = types.model({
    activities: ProgressMeasure,
    units: ProgressMeasure
});
/*.preProcessSnapshot(sn => sn && ({
  ...sn,
  activities: { ...sn.activities, progressType: 'activities' },
  units: { ...sn.units, progressType: 'units', },
  })); */


export const Pour = types.model("PourBrick", {
    id: types.identifier,
    type: types.frozen<BrickTypes.S>(),
    name: types.maybeNull(types.string),
    tower: types.string,
    phase: types.optional(types.string, "structures"),
    spaceType: types.optional(types.string, "tower"),
    yVal: CellAddress,
    xVal: CellAddress,
    status: eStatus,
    plan: types.maybeNull(DateDiff),
    actual: types.maybeNull(DateDiff),
    lastUpdated: types.maybeNull(DateType),
    progress: types.refinement(types.number, val => val >= 0 && val <= 1),
    progressDetails: types.maybeNull(ProgressDetails),
    pourType: types.maybeNull(types.enumeration(['conventional', 'aluminum', 'aluminium']))
}).views(self => {
    return {
        get width(): number { return getEnv(self).brick[self.phase][(getRoot(self) as Instance<typeof LateStoreModel>).params.screen].width; },
        get height(): number { return getEnv(self).brick[self.phase][(getRoot(self) as Instance<typeof LateStoreModel>).params.screen].height; },
        get padding(): { [K in 'x' | 'y']: number; } { return getEnv(self).scale[self.phase][(getRoot(self) as Instance<typeof LateStoreModel>).params.screen]; }
    };
}).views(self => ({
    get detail(): SnapshotOrInstance<typeof eProgressTypes> { return (getRoot(self) as Instance<typeof LateStoreModel>).filters.progressType; },
    get screen(): SnapshotOrInstance<typeof eScreen> { return (getRoot(self) as Instance<typeof LateStoreModel>).params.screen; },
    // get phase() { return (getRoot(self) as Instance<typeof LateStoreModel>).params.phase; },
})).views(self => ({
    get progressView(): string | null {
        switch (self.detail) {
            case 'activities': return self.progressDetails ? self.progressDetails.activities.display : null;
            case 'units': return self.progressDetails ? self.progressDetails.units.display : null;
            case 'days': return self.plan && self.plan.to ? `${(-1 * self.plan.diffTo!).toLocaleString('en', { minimumIntegerDigits: 2 })} D` : null;
            default: return "tsk tsk";
        }
    },
    get progressMultiplier(): number {
        return self.progress || 1;
    },
    get progressAsPercent(): string {
        const val: string = (self.progress * 100).toFixed(0);
        return `${val} %`;
    }

}))
    .views(self => ({
        get color(): string {
            return getEnv(self).brickPalette[self.phase][self.screen][self.status];
        },
        get startDelay(): number | false {
            if (self.actual && self.actual.from)
                return !!self.plan && !!self.plan.from && !!self.actual && !!self.actual.from && (self.actual.from.diff(self.plan.from, 'days') > 0) ? self.actual.from.diff(self.plan.from, 'days') : false;
            else
                return !!self.plan && !!self.plan.from && (self.plan.diffFrom! > 0) ? self.plan.diffFrom! : false;
        },
        get endDelay(): number | false {
            if (self.actual && self.actual.to)
                return !!self.plan && !!self.plan.to && !!self.actual && !!self.actual.to && (self.actual.to.diff(self.plan.to, 'days') > 0) ? self.actual.to.diff(self.plan.to, 'days') : false;
            else
                return !!self.plan && !!self.plan.to && (self.plan.diffTo! > 0) ? self.plan.diffTo! : false;
        },
        get delayed(): boolean {
            switch (self.status) {
                case 'started': return !!self.plan && !!self.plan.to && self.plan.diffTo! > 0;
                case 'notStarted': return !!self.plan && !!self.plan.from && self.plan.diffFrom! > 0;
                case 'completed': return !!self.plan && !!self.plan.to && !!self.actual && !!self.actual.to && self.actual.to.diff(self.plan.to, 'days') > 0;
                default: return false;
            }
        }
    }))
    .views(self => (
        {
            get delta(): SnapshotOrInstance<typeof IdDateDiff> {
                return (getRoot(self) as Instance<typeof LateStoreModel>).filters.delta;
            },
            get statusFiltered(): boolean {
                return !(getRoot(self) as Instance<typeof LateStoreModel>).filters.activeFilters.includes('status')
                    ? true
                    : (getRoot(self) as Instance<typeof LateStoreModel>).filters.hasStatus(self.status);
            },
            get delayedFiltered(): boolean {
                return !(getRoot(self) as Instance<typeof LateStoreModel>).filters.delayed ? true : self.delayed;
            }
        }
    ))
    .views(self => ({
        get filtered(): boolean {
            if ((getRoot(self) as Instance<typeof LateStoreModel>).filters.activeFilters.length === 0) { return true; }
            const filter: boolean = self.statusFiltered && self.delayedFiltered;
            if (!filter) { return false; }
            const delta = self.delta.delta;
            if (filter && !delta) { return true; }
            if (!(delta && !!delta.from && !!delta.to && (delta as Instance<typeof DateDiff>).diff)) { return false; }
            const [greater, lesser] = (delta! as Instance<typeof DateDiff>)!.diff! > 0 ? [delta.to, delta.from] : [delta.from, delta.to];
            return !!self.lastUpdated && moment(self.lastUpdated!).isBetween(moment(lesser), moment(greater));
        }
    }))
    .views(self => ({
        get activityLastUpdated(): boolean {
            const yesterday = moment().subtract(1, 'day');
            return !!self.lastUpdated && (self.lastUpdated! as Moment)!.isSame(yesterday, 'day');
        }
    }));

export interface IPour extends Instance<typeof Pour> { };

export function snToPourBrick(sn: { [k: string]: any; }) {
    return {
        ...sn,
        type: BrickTypes.S,
        progressDetails: {
            activities: { ...sn.progressDetails?.activities, progressType: 'activities' },
            units: { ...sn.progressDetails?.units, progressType: 'units' }
        },
        xVal: { type: CellAddressType.POUR_NUM, id: sn.xVal, name: `Pour ${sn.xVal}` },
        yVal: { type: CellAddressType.FLOOR_NUM, id: sn.yVal, name: sn.floor || (sn.yVal as number).toLocaleString('en', { minimumIntegerDigits: 2 }) },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        status: __hardcoded[sn.status as keyof IHardcoded] ? __hardcoded[sn.status as keyof IHardcoded] : "plainWrong",
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    }
}
