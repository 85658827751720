import React, { Dispatch, memo, SetStateAction, ChangeEvent } from "react";
import useStyles from "./SortStyles";
import SortSmall from "../../assets/images/Sort - Small.svg"
import clsx from 'clsx'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { RadioProps } from "@material-ui/core/Radio";

function StyledRadio(props: RadioProps) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.sortRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function Sort({ selected, setOption, options: opts, callback }: { callback: (arg: string | null) => void; selected: string | null; setOption: Dispatch<SetStateAction<string | null>>; options?: { id: string; name: string; }[]; }) {
  const classes = useStyles();
  const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => { setOption(ev.target.value || null); callback(ev.target.value || null); }
  const options = opts || [{ id: 'ascending', name: "Ascending" }, { id: 'descending', name: "Descending" }];
  return (
    <FormControl component="fieldset">
        <label className={classes.sort}>SORT</label>
      <RadioGroup aria-label="sort" name="sort" value={selected || ''} onChange={changeHandler}>
        {options.map(({ id, name }) =>
          <FormControlLabel value={id} key={id} control={<StyledRadio />} label={name} style={{ margin: 0, fontWeight: (selected === id) ? "bold" : "normal" }} />
        )
        }

      </RadioGroup>
    </FormControl>
  );
}

export default memo(Sort);
