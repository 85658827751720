import React, {
  memo,
  useCallback,
  useMemo,
  useEffect,
  useState
} from "react";
import { useHistory, useLocation } from "react-router";
import useAccordion from "../filter/UseAccordionHook";
import Checkbox from "../filter/Checkbox";
import { DefaultList } from "../filter/DefaultAccordianRender";
import useSearch from "../search/useSearch";
import useSort from "../sort/useSort";
import useStyles from "./TableMultiselectStyles";
import { useStore } from '../../models/ProvideModel';
import { useObserver } from "mobx-react-lite";
const TempTitle = memo(({ title }: { title: string; }) => (<div>{title}</div>));

type IHeaderData1 = Array<{ [T: string]: { id: string; name?: string; }; }>;
type IHeaderData2 = { id: string; name?: string; }[];

export interface IFatColumnData<T extends string = ''> {
  column: {
    headerObj?: T;
    data: T extends keyof IFatColumnData<T>['column']['headerObj'] ?
    IHeaderData1 : IHeaderData2;
    name: string;
    id: string;
    [K: string]: any;
  };
};

function Filter<T extends string = ''>({ column: { data, name, id, headerObj } }: IFatColumnData<T>) {
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const { push } = useHistory();
  const classes = useStyles();
  const store = useStore();
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const [snagAgeApplied, setSnagAgeApplied] = useState(false)

  useEffect(() => {
    setSnagAgeApplied(searchParam.getAll('sort').includes("created_at") && searchParam.getAll('status').includes("open") && searchParam.getAll('status').includes("for review"))
  }, [searchParam]);

  const sortOptions = useMemo(() => {
    const defaultSort = [{ id: 'ascending', name: "Sort A-z" }, { id: 'descending', name: "Sort Z-a" }];
    switch (id) {
      case "floor_idx": return [{ id: 'ascending', name: "Sort Bottom to Top" }, { id: 'descending', name: "Sort Top to Bottom" }];
      case "step": return [{ id: 'ascending', name: "Sort First-Last" }, { id: 'descending', name: "Sort Last-First" }];
      case "activity_type_id":
        return [
          { id: 'ascending_pre', name: "Order of work - ascending" },
          { id: 'descending_pre', name: "Order of work - descending" },
        ];
      default: return defaultSort;
    }
  }, []);

  const current: number[] | string[] = useMemo(
    () => id === 'floor_idx' ? searchParam.getAll(id).map(n => Number.parseInt(n)) : searchParam.getAll(id),
    [search, id]
  );
  const initialState = useCallback(() => current, []);
  const sortInitial = useMemo(() => {
    const ascList = searchParam.getAll('sort');
    if (ascList.includes(id)) {
      if (id === "activity_type_id") {
        return 'ascending_pre'
      }
      else return 'ascending';
    }
    const descList = searchParam.getAll('sortDesc');
    if (descList.includes(id)) {
      if (id === "activity_type_id") {
        return 'descending_pre'
      }
      else return 'descending';
    }
    return null;
  }, []);
  const [state, Filter] = useAccordion<{ id: string | number; name?: string; }, 'id'>(DefaultList, { reset: true }, initialState);
  const disabled = useMemo(() => {
    if (state.length === current.length) {
      return state.length === 0
        ? true
        : state.every((el: string | number) =>
          (current as Array<typeof el>).includes(el)
        );
    } else {
      return false;
    }
  }, [state, current]);
  const applyHandler = useCallback(() => {
    searchParam.delete(id);
    searchParam.delete('page');
    state.forEach((v) => {
      searchParam.append(id, v.toString());
    });
    push({ ...location, search: searchParam.toString() });
  }, [state, id]);
  const applyReset = () => {
    const ascList = searchParam.getAll('sort');
    const descList = searchParam.getAll('sortDesc');
    searchParam.delete('sort');
    searchParam.delete('sortDesc');
    ascList.forEach(val => {
      if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
        return null;
      }
      if (val === id) {
        return null;
      }
      searchParam.append('sort', val);
    });
    descList.forEach(val => {
      if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
        return null;
      }
      if (val === id) {
        return null;
      }
      searchParam.append('sortDesc', val);
    });
    searchParam.delete(id);
    push({ ...location, search: searchParam.toString() });
  }
  const normalizedData: { id: string; name: string; }[] = useMemo(() => data ?
    headerObj ?
      (data as IHeaderData1).map(obj => ({
        id: obj[headerObj].id,
        name: obj[headerObj].name || obj[headerObj].id
      }))
      :
      (data as IHeaderData2).map(({ id, name }) => ({ id, name: name || id }))
    : [], [data]);
  const [newOpts, Search] = useSearch(normalizedData, { title: name });
  const [sortState, Sort] = useSort(sortInitial, sortOptions);
  const sortCb = useCallback((sortState: string | null) => {
    // TODO bind sort effects to click
    searchParam.delete('page');
    switch (sortState) {
      case 'ascending':
        if (!searchParam.getAll('sort').includes(id)) {
          searchParam.append('sort', id);
          if (searchParam.getAll('sortDesc').includes(id)) {
            const newDesc = searchParam.getAll('sortDesc').filter(el => el !== id);
            searchParam.delete('sortDesc');
            newDesc.forEach(val => { searchParam.append('sortDesc', val); });
          }
        }
        break;
      case 'descending':
        if (!searchParam.getAll('sortDesc').includes(id)) {
          searchParam.append('sortDesc', id);
          if (searchParam.getAll('sort').includes(id)) {
            const newAsc = searchParam.getAll('sort').filter(el => el !== id);
            searchParam.delete('sort');
            newAsc.forEach(val => { searchParam.append('sort', val); });
          }
        }
        break;
      case 'ascending_pre':
        if (!searchParam.getAll('sort').includes(id)) {
          searchParam.append('sort', 'precedence');
          searchParam.append('sort', id);
          if (searchParam.getAll('sortDesc').includes(id)) {
            const newDesc = searchParam.getAll('sortDesc').filter(el => {
              if (el !== id) {
                if (id === "activity_type_id" && el === "precedence") {
                  return false;
                }
                return true;
              }
              return false;
            });
            searchParam.delete('sortDesc');
            newDesc.forEach(val => { searchParam.append('sortDesc', val); });
          }
        }
        break;
      case 'descending_pre':
        if (!searchParam.getAll('sortDesc').includes(id)) {
          searchParam.append('sortDesc', 'precedence');
          searchParam.append('sortDesc', id);
          if (searchParam.getAll('sort').includes(id)) {
            const newAsc = searchParam.getAll('sort').filter(el => {
              if (el !== id) {
                if (id === "activity_type_id" && el === "precedence") {
                  return false;
                }
                return true;
              }
              return false;
            });
            searchParam.delete('sort');
            newAsc.forEach(val => { searchParam.append('sort', val); });
          }
        }
        break;
      default:
        if (searchParam.getAll('sort').includes(id)) {
          const newAsc = searchParam.getAll('sort').filter(el => el !== id);
          searchParam.delete('sort');
          newAsc.forEach(val => { searchParam.append('sort', val); });
        }
        if (searchParam.getAll('sortDesc').includes(id)) {
          const newDesc = searchParam.getAll('sortDesc').filter(el => el !== id);
          searchParam.delete('sortDesc');
          newDesc.forEach(val => { searchParam.append('sortDesc', val); });
        }
    }
    push({ ...location, search: searchParam.toString() });
  }, [id]);
  return useObserver(() => (
    <Filter
      options={newOpts}
      Render={Checkbox}
      disabled={disabled}
      callback={applyHandler}
      reset={applyReset}
      title={<TempTitle title={name} />}
      sortSelected={sortState}
      snagSortApplied={snagAgeApplied}
      headersCount={store.exportTableFilters.headersDataCount}
    >
      <div className={store.responsiveUtils.currentViewport.isLg ? classes.sortWrapper : classes.sortWrapperSm}>
        <Sort selected={sortState} callback={sortCb} />
        {!(snagAgeApplied && name.toLowerCase() === "status") &&<Search />}
      </div>
    </Filter>
  ));
}
export default memo(Filter);
