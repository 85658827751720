import React, { memo, FunctionComponent, useState, useEffect } from 'react';
import { IChan } from 'csp-with-ts';
import { IList, IUseMux } from './UseAccordionHook';
import useConnect from './UseConnectToStateHook';
import LoadingSpinner from '../loadingSkelaton/LoadingSpinner';

const Provider = memo(<T extends Record<string, any>, K extends keyof T>({ broadcast, relay, RenderList, ...props }: { broadcast: IChan<T[K][]>, relay: IChan<IUseMux<T, K>>; RenderList: FunctionComponent<Record<string, any>>; options: T[]; RenderFn: FunctionComponent<Record<string, any>> }) => {
  const { state, setState } = useConnect<T, K>(relay, broadcast);
  return (<RenderList
    state={state}
    setState={setState}
    {...props}
  />);
});

export default Provider;

export const DefaultList = memo(({ state, setState, options, RenderFn }: IList<{ id: string, name: string; }, 'id'>) => {
  const [ret, setRet] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const cancel = setImmediate(() =>
      setRet(options?.map(({ id, name }) => (
        <RenderFn
          key={id}
          id={id}
          value={state}
          handleClick={setState}
          name={name}
        />
      )
      )));
    return () => { if (cancel) { clearImmediate(cancel); } }
  }, [options, state, setState, RenderFn]);
  // Replace Loading div with actual component
  return options?.length && !ret?.length ? <LoadingSpinner width="100%" /> : <> {ret}</>;
});
