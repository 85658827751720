import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    sortWrapper: {
        padding: "0px 16px 8px 16px", 
        top: 33, 
        zIndex: 99999, 
        backgroundColor: "#fff" ,
        position: "sticky"
    },
    sortWrapperSm: { 
        padding: "0px 16px 8px 16px", 
        top: 33, 
        zIndex: 99999, 
        backgroundColor: "#fff" ,
        height:"auto"
    }
}));

export default useStyles;