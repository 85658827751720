/* import React, { Dispatch, ReducerAction, memo, useEffect, useCallback, useMemo } from 'react'; */
import React, { Dispatch, ReducerAction, memo } from 'react';
/* import useAccordion from './UseAccordionHook';
 * import { DefaultList } from './DefaultAccordianRender';
 * import { putAsync } from 'csp-with-ts';
 * import { SelectedState } from './SelectedState';
 * import SelectedItem from './SelectedItem'; */
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';

export interface IFormDropdownInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: string;
  label: string;
  options: string[];
  disabled?: boolean;
};

/* const FormDropdown = ({ field, dispatch, state, label, options, disabled }: IFormDropdownInputProps) => {
 *   const newOptions = useMemo(() => options.map(opt => ({ id: opt, name: opt })), [options]);
 *   const initialState = useCallback(() => state.length ? [state] : [], [state]);
 *   const [dropdownState, ByFormDropdown, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, accordian: false, apply: false, reset: false }, initialState);
 *   useEffect(() => { if (dropdownState.length) { dispatch({ type: 'REPLACE_GENERIC', payload: { input: dropdownState[0], field } }); } }, [dropdownState]);
 *   useEffect(() => { putAsync(relay, { type: 'toggle', payload: false }); }, [state]);
 *   return !!disabled ? (<input disabled type="text" value={state} />) : (
 *     <ByFormDropdown
 *       options={newOptions}
 *       Render={SelectedItem}
 *       title={<SelectedState title={label} state={state} />}
 *     />
 *   )
 * }; */
const FormDropdown = ({ field, dispatch, state, label, options, disabled }) => (
  <FormControl>
    <InputLabel id={`${field}_${label}`}>{label}</InputLabel>
    <Select
      value={state}
      onChange={e => { const val = e.target.value; dispatch({ type: 'REPLACE_GENERIC', payload: { input: val, field } }) }}
    >
      {options.map(opt => <MenuItem key={opt} value={opt}>{opt}</MenuItem>)}
    </Select>
  </FormControl>
);

export default memo(FormDropdown);
