import moment from "moment";
import { CellAddressType } from "../models/enums";
import { Instance } from "mobx-state-tree";
import { CellAddress, CellAddressActivities } from "../models/Brick/CellAddress";
import { Filters } from "../models/Common";

// helper constants
const __hardcoded = {
    "1_planned": "notStarted",
    "3_active": "OnGoing",
    "8_done": "completed"
};
const PARTITION = 8;
const MAP = {
    0: "01",
    1: "02",
    2: "04",
    3: "08",
    4: "10",
    5: "20",
    6: "40",
    7: "80",
    NONE: "00",
    ALL: "FF"
};
const maskMap = Array.from(Object.keys(MAP), (k) => parseInt('0x' + MAP[k], 16));

/*
  Pluck only given indices
  ,----
  |   For an array,
  |   efficiently gives you back the elements
  |   at the provided indices.
  `----
*/

export function pluck<T>(this: T[], ...args: number[]): T[] {
    let returnArr: T[] = [], i = 0;
    const argsLen = args.length;
    if (argsLen === 0) { return this; }
    // if (intent) {
    for (; i < argsLen; i++) { returnArr.push(this[args[i]]); }
    // }
    // else {
    //     const len = this.length;
    //     returnArr = Array.from(this);
    //     for (; i < argsLen; i++) { returnArr[argsLen[i]] = returnArr[len - i - 1]; returnArr.pop(); }
    // }
    return returnArr;
}



/*
  Binary to Index
  ,----
  | For an ArrayBuffer,
  | efficiently gives you back the indices that should be filtered.
  `----
*/

export function giveIndices(this: ArrayBuffer): number[] {
    const arr: Uint8Array = new Uint8Array(this);
    if (arr.every(el => el === 0)) { return []; }
    if (arr.every(el => el === 255)) { return []; }
    let loop: number = 0;
    const view = Array.from(arr, (val: number, index: number) => {
        if (val === 0) { return []; }
        else if (0 === (val ^ maskMap[maskMap.length - 1])) {
            const lBound: number = index * PARTITION;
            return Array.from(Array(PARTITION).keys(), el => el + lBound);
        }
        else {
            let res: number[] = [];
            const lBound: number = index * PARTITION;
            for (loop = 0; loop < PARTITION; loop++) {
                if (val & maskMap[loop]) { res.push(lBound + loop); }
            }
            return res;
        }
    });
    return view.flat();
}

export function indicesToEncoded(values: number[], len: number): string {
    return encodeURIComponent(String.fromCharCode.apply(null, values.reduce((acc: Uint8Array, index: number) => {
        const [i, val] = [Math.floor(index / PARTITION), index % PARTITION];
        acc[i] = acc[i] | parseInt("0x" + MAP[val], 16);
        return acc;
    }, new Uint8Array(Math.ceil(len / PARTITION)).fill(0))
    ));
}

/**
 * Function that processes the column header for the excel data.
 **/
export function processExcelData(columns: any, data: any) {
    let res = [] as any;
    data.forEach(el => {
        let row = {};
        columns.forEach(col => {
            // currently only isExcelField prop is checked. This means if any
            // column is set to invisible dynamically in the future (there's no
            // table currently where that's the case), you have to add the
            // condition here too.
            if (col.isExcelField)
                row[col.name] = col.excelExport(el);
        });
        res.push({ ...row });
    });
    return res;
}

export function isDelayed(statusString: string, planFrom?: string, planTo?: string, _?: string, actualTo?: string): boolean {
    const today = moment().startOf('day');
    const status = __hardcoded[statusString] || "plainWrong";
    switch (status) {
        case 'started': return !!planTo && today.diff(moment(planTo), 'days') > 0;
        case 'notStarted': return !!planFrom && today.diff(moment(planFrom), 'days') > 0;
        case 'completed': return !!planTo && !!actualTo && moment(actualTo).diff(moment(planTo), 'days') > 0;
        default: return false;
    }
}

export function isDelayedEnd(planTo?: string, actualTo?: string): boolean {
    if (actualTo)
        return !!planTo && !!actualTo && moment(actualTo).diff(moment(planTo), 'days') > 0;
    else
        return !!planTo && moment().startOf('day').diff(moment(planTo), 'days') > 0;
}

export function isDelayedStart(planFrom?: string, actualFrom?: string): boolean {
    if (actualFrom)
        return !!planFrom && !!actualFrom && moment(actualFrom).diff(moment(planFrom), 'days') > 0;
    else
        return !!planFrom && moment().startOf('day').diff(moment(planFrom), 'days') > 0;
}

export function getStatusName(id: string): string {
    return __hardcoded[id] || "Incorrect Value";
}

export function extendStyles<T>(obj: object, newObj: object) {
    return { ...obj, ...newObj };
}
// TODO: @Aniket Mule: Kindly change OnGoing back to started, or convert every
// instance of started to OnGoing.
export function calcStatus(completed: number, inProgress: number, total: number): "completed" | "OnGoing" | "notStarted" | "plainWrong" {
    if (total < completed + inProgress) { return "plainWrong"; }
    else if (total === completed) { return "completed"; }
    else if (inProgress || completed) { return "OnGoing"; }
    else if (inProgress === 0 && completed === 0) { return "notStarted"; }
    return "plainWrong"
}

export function getReportName(name: string) {
    switch (name) {
        case "barChart": return "Bar Chart";
        case "table": return "By Units";
        case "brickGraph": return "By Floors";
        case "baratheon": return "By Floors";
        default: return "None"
    }
}

export const sortingStrategyRev = (a, b) => {
    if (typeof a.id === "number" && typeof b.id === 'number')
        return b.id - a.id;
    return (b.name as string).localeCompare(a.name as string);
};

export const sortingStrategy = (a, b) => {
    if (typeof a.id === "number" && typeof b.id === 'number')
        return a.id - b.id;
    return (a.name as string).localeCompare(b.name as string);
};

export const sortingStrategyName = (a, b) => {
    return (a.name as string).localeCompare(b.name as string);
};

export const sortingWithPrecedence = (a, b) => {
    if (a.precedence === undefined && b.precedence === undefined) {
        return sortingStrategyName(a, b);
    }
    if (a.precedence === null && b.precedence !== null) {
        return 1;
    }
    if (a.precedence !== null && b.precedence === null) {
        return -1;
    }
    return ((a.precedence || 0) - (b.precedence || 0) === 0) ? sortingStrategyName(a, b) : a.precedence! - b.precedence!;
}

function assignValue(object, key, value) {
    object[key] = value;
}

export const groupKeyBy = (input, key) => {
    return input?.reduce((result, currentValue) => {
        let groupKey = currentValue[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(currentValue);
        return result;
    }, {});
};

/** Used to check objects for own properties. */
const hasOwnProperty = Object.prototype.hasOwnProperty

export const groupBy = (input, cb) => {
    return input?.reduce((result, currentValue, key) => {
        key = cb(currentValue)
        if (hasOwnProperty.call(result, key)) {
            result[key].push(currentValue)
        } else {
            assignValue(result, key, [currentValue])
        }
        return result
    }, {})
}

export interface IActivityColumnsArgs {
    columnType: CellAddressType;
    columns: Instance<typeof CellAddress>[];
    columnSorted: Instance<typeof CellAddress>[];
    filterRef: Instance<typeof Filters>;
};

export function getColumnsFiltered({ columnType, columns, columnSorted, filterRef }: IActivityColumnsArgs): Instance<typeof CellAddress>[] {
    if (columnType !== CellAddressType.ACT_STRING) { return columnSorted; }
    const arrayBuffer = new ArrayBuffer(Math.ceil(columns.length / 8));
    const len = arrayBuffer.byteLength;
    const decoded = filterRef.activity && decodeURIComponent(filterRef.activity);
    if (!decoded) { return columnSorted; }
    let view = new Uint8Array(arrayBuffer), loop = 0;
    for (loop = 0; loop < len; loop++) { view[loop] = decoded.charCodeAt(loop); }
    const indices = giveIndices.apply(arrayBuffer);
    return indices.length === 0 ? columnSorted : pluck.apply(columnSorted, indices);
};

export function getIndicesCol({ columnType, columns, columnSorted, filterRef }: IActivityColumnsArgs): number[] {
    if (!filterRef.activity) { return []; }
    if (columnType !== CellAddressType.ACT_STRING) { return []; }
    const arrayBuffer = new ArrayBuffer(Math.ceil(columns.length / 8));
    const len = arrayBuffer.byteLength;
    const decoded = filterRef.activity && decodeURIComponent(filterRef.activity);
    if (!decoded) { return []; }
    let view = new Uint8Array(arrayBuffer), loop = 0;
    for (; loop < len; loop++) { view[loop] = decoded.charCodeAt(loop); }
    return giveIndices.apply(arrayBuffer);
};

export function getStages({ columnSorted }: { columnSorted: Instance<typeof CellAddressActivities>[]; }): { stage: string; length: number; index: number; order: number }[] {
    const interArray: {
        stage: string;
        index: number;
        order: number;
    }[] = columnSorted.reduce(
        (
            acc: { stage: string; index: number; order: number }[],
            column: Instance<typeof CellAddressActivities>,
            ind: number
        ) =>
            column.stage &&
                column.stage !==
                (columnSorted[ind > 0 ? ind - 1 : 0] &&
                    columnSorted[ind > 0 ? ind - 1 : 0].stage)
                ? [
                    ...acc,
                    {
                        stage: columnSorted[ind > 0 ? ind - 1 : 0].stage,
                        index: ind - 1,
                        order: acc.length > 0 ? acc[acc.length - 1].order + 1 : 0
                    }
                ]
                : ind + 1 === columnSorted.length
                    ? [
                        ...acc,
                        {
                            stage: column.stage,
                            index: ind + 1,
                            order: acc.length > 0 ? acc[acc.length - 1].order + 1 : 0
                        }
                    ]
                    : acc,
        []
    );
    return interArray.reduce(

        (acc, { stage, index, order }, ind) => [
            ...acc,
            {
                stage,
                order,
                index: ind === 0 ? 0 : interArray[ind - 1].index,
                length: ind === 0 ? index : index - interArray[ind - 1].index
            }
        ],
        []
    );
}

export function getPdf(url: string, fileName: string) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
}

export function groupByKeys(keys: string[], input: { [K: string]: any; }[]) {
    let internalMap: { [K: string]: any[]; } = {}, i = 0, len = input.length, res: Array<Array<any>> = [];
    for (i; i < len; i++) {
        const record = input[i];
        const internalKey = keys.map(k => record[k]).join('_');
        if (!internalMap[internalKey]) { internalMap[internalKey] = [keys.reduce((acc, k) => ({ ...acc, [k]: record[k] }), {})]; }
        internalMap[internalKey].push(record);
    }
    Object.keys(internalMap).forEach(k => {
        res.push([internalMap[k][0], internalMap[k]?.slice(1)]);
    })
    return res;
}

export function getFileNameFromRow(original: { [K: string]: any; }): string {
    const fileName = original['block_name'] + "-" + original['unit_name'] + "-" + original['activity_type_name'] + ".pdf";
    return fileName.replaceAll("/", "-");
}

export function transformDate(val: string): string { return moment(val).startOf('day').toISOString(true); }
export function transformTime(val: string): string { return moment().startOf('day').add(moment.duration(val.split(':')[0], 'h')).add(moment.duration(val.split(':')[1], 'm')).toISOString(true); }
export function transformDateTime(val: string): string { return moment(val).toISOString(true); }
export function transformPercent(val: string): string { return `${val.replace(/[^0-9]+/g, "")} %`; }
export function transformPhoto(val: string[]) { return val.length ? val : ""; }
// export function transformNumeric(val: number): string { return val.toString(); }
// Expect to put some transform on photo


export const formEditReducer = (state: { [K: string]: any; }, action: { type: string; payload: any; }) => {
    switch (action.type) {
        case 'ADD_IMAGE': return { ...state, isChanged: true, [action.payload.field]: [...state[action.payload.field], action.payload.input] };
        case 'REMOVE_IMAGE': return { ...state, isChanged: true, [action.payload.field]: state[action.payload.field].filter(file => file != action.payload.input) };
        case 'REPLACE_GENERIC':
        case 'REPLACE_DATE_TIME': return state[action.payload.field] === action.payload.input ? state : { ...state, isChanged: true, [action.payload.field]: action.payload.input };
        case 'RESET': return { ...action.payload, isChanged: false };
        default: return state;
    }
}
