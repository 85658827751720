import React, { useEffect, useState, memo, FunctionComponent, useMemo, useCallback } from 'react';
import { useParams, useHistory } from "react-router";
import { compose, transduce, map, toArray } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { GET_ALL_FORMS_DATA, GET_ACTIVITY_DATA_FOR_FORM, GET_ALL_FORM_FIELDS, GET_FORMS } from '../../utils/queries';
import client from "../../utils/apolloClient";
import BlockCell from '../../components/BlockCell';
import { StatusWithDelayed, DateTimeFormat, RenderImage } from '../../components/table/Commonfunctions';
import DefaultCells from '../../components/DefaultCells';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import ThinTable from '../../components/ThinTable/ThinTable';
import { useStore } from '../../models/ProvideModel';
import config from '../../configs/clientConfig';
import moment from 'moment';
import useAccordion from '../../components/filter/UseAccordionHook';
import { DefaultList } from '../../components/filter/DefaultAccordianRender';
import { observer } from 'mobx-react-lite';
import RadioButton from '../../components/filter/RadioButton';
import { SelectedState } from '../../components/filter/SelectedState';
import useStyles from './FormDataAggregateStyles'
import RightArrow from '../../assets/images/right-arrow.png'
import { putAsync } from 'csp-with-ts';

/* Should use store.params.toPath and all that but not importing the whole
   observable for just that. */
const __SECTION = "form-data";

export interface IColumnOptions {
  id: string;
  name: string;
  value?: string;
  Cell?: FunctionComponent;
  print?: boolean;
  accessor?: string;
  minWidth?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  inputType?: string;
  group?: string;
};
// TODO do some cool shit with the prior knowledge of inputTypes. You can change
// the Cell at least.
const inputTypes = {
  "photo": (col: string) => ({ Cell: RenderImage, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].map(v => `${config.baseURL}large/${v}`).join(',') }),
  "date": (col: string) => ({ Cell: DateTimeFormat, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].length && moment(elt[col]).format("YYYY-MM-DD") }),
  "time": (col: string) => ({ Cell: DateTimeFormat, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].length && moment(elt[col]).format("HH:mm") }),
  "datetime": (col: string) => ({ Cell: DateTimeFormat, isExcelField: true, excelExport: (elt: any) => elt[col] && elt[col].length && moment(elt[col]).format("YYYY-MM-DD HH:mm") })
};
const __defaultExport = (col: string) => ({ isExcelField: true, excelExport: (elt: any) => elt[col] });

const FormDataColumns: IColumnOptions[] = [
  { id: "block_id", value: "block", accessor: "block.id", name: "Block", print: true, Cell: BlockCell, headerObj: "block", minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['block']['title'], group: "Unit Activity" },
  { id: "floor_idx", value: "floor_name", name: "Floor", print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['floor_name'], group: "Unit Activity" },
  /* { id: "precedence", value: "precedence", name: "Precedence", isVisible: false, Component: TableMultiselect, print: false, minWidth: 100, isExcelField: false, excelExport: (elt: any) => elt['precedence'] }, */
  { id: "activity_type_id", value: "activity_name", name: "Activity Name", print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['activity_name'], group: "Unit Activity" },
  { id: "unit_id", value: "unit_name", name: "Unit Title", print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_name'], group: "Unit Activity" },
  /* { id: "pour_number", name: "Pour", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['pour_number'] }, */
  { id: "unit_type_id", value: "unit_type_title", name: "Unit Type", print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_type_title'], group: "Unit Activity" },
  { id: "phase_id", name: "Phase", value: "phase_title", minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['phase_title'], group: "Unit Activity" },
  { id: "space_type_id", name: "Space Type", value: "space_type_title", isExcelField: true, excelExport: (elt: any) => elt['space_type_title'], group: "Unit Activity" },
  /* { id: "current_step", name: "Step", Cell: StepNameDetails, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_step'] }, */
  { id: "status_desc", name: "Activity Status", Cell: StatusWithDelayed, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['status'] === '1_planned' ? 'Not Started' : elt['status'] === '3_active' ? 'On Going' : elt['status'] === '8_done' ? 'Completed' : 'Error', group: "Unit Activity" },
  /* { id: "current_user_name", name: "Currently With", Component: TableMultiselect, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_user_name'] }, */
  /* { id: "last_updated", name: "Last Updated", filter: delta, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['last_updated'] ? '-' : moment(elt['last_updated']).format('DD/MM/YYYY') }, */
  /* { id: "plan_start", name: "Plan Start", filter: delta, Component: TableDate, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_start'] ? '-' : moment(elt['plan_start']).format('DD/MM/YYYY') },
   * { id: "actual_start", name: "Actual Start", filter: delta, Component: TableDate, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['actual_start'] ? '-' : moment(elt['actual_start']).format('DD/MM/YYYY') },
   * { id: "plan_end", name: "Plan End", filter: delta, print: true, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_end'] ? '-' : moment(elt['plan_end']).format('DD/MM/YYYY') },
   * { id: "actual_end", name: "Actual End", filter: delta, Cell: RenderedDate, Component: TableDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['actual_end'] ? '-' : moment(elt['actual_end']).format('DD/MM/YYYY') }, */
];

const FormDataAggregate = memo(({ formId }: { formId: string; }) => {
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const store = useStore();
  const columnsConfig = useMemo(() => transduce(
    columns,
    compose(
      /* filter(({ print: printV }) => (print === "print" ? !!printV : true)), */
      /* filter(({ id }) =>
       *   id === "pour_number" ? phase === "structures" ? true : false : true
       * ), */
      map(({ id, name, Component, Cell, print, accessor, headerObj, minWidth, ...rest }) => ({
        name,
        Header:
          Component && print !== "print"
            ? Component
            : name,
        minWidth: minWidth || 150,
        id,
        accessor: accessor || id,
        headerObj,
        Cell: !!Cell ? Cell : DefaultCells,
        ...rest
      })),
      /* partitionBy(({ group }) => group), */
      /* map(([{ group, ...rest }, ...arr]) => ({ Header: group, columns: [{ ...rest, group }, ...arr] })) */
    ),
    toArray()
  ), [columns]);
  useEffect(() => { if (!client.client) { client(); } }, [client]);
  useEffect(() => {
    if (client.client && formId) {
      setLoading(true);
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_ALL_FORMS_DATA, variables: { formId } })
        .subscribe(({ data: { formData }, loading }) => {
          if (!loading) {
            if (formData?.length) {
              const activities = formData?.map(({ activity }) => activity) || [];
              let formFields: string[] = [];
              formData?.forEach(({ inputData }) => {
                for (let k in inputData) {
                  if (!formFields.includes(k)) { formFields.push(k); }
                }
              });
              /* const formFieldsConfig = formFields?.map(({ position, fieldConfig: { data: { id, label, inputType: input_type, ...rest } } }) => ({
               *   id,
               *   name: label,
               *   print: true,
               *   value: label,
               *   ...rest
               * })) || []; */
              const fieldsQuery = (client.client as ApolloClient<NormalizedCacheObject>)!
                .watchQuery({ query: GET_ALL_FORM_FIELDS, variables: { formFields } })
                .subscribe(({ data: { formFields }, loading: innerLoading }) => {
                  if (!innerLoading) {
                    const formFieldsConfig = formFields?.map(
                      ({ id, data: { label, input_type: inputType, ...rest } }) => ({
                        id,
                        name: label,
                        print: true,
                        value: label,
                        group: "Form",
                        inputType,
                        ...(inputTypes[inputType] && inputTypes[inputType](id)) || __defaultExport(id),
                        ...rest
                      })
                    ) || [];
                    setColumns(() => [...FormDataColumns, ...formFieldsConfig]);
                  }
                });
              const activitiesQuery = (client.client as ApolloClient<NormalizedCacheObject>)!
                .watchQuery({ query: GET_ACTIVITY_DATA_FOR_FORM, variables: { activities, properties: store.projectInfo.currentProject.properties.map(({ id }) => id) } })
                .subscribe(({ data: { activities: fetchedActivities }, loading: innerLoading }) => {
                  if (!innerLoading) {
                    setData(() => fetchedActivities.map((obj, i) => ({ ...obj, ...(formData[i]?.inputData || {}) })));
                    setLoading(false);
                  }
                });
            }
            else { setLoading(false) };
          }
        });
      return () => { query.unsubscribe(); }
    }
  }, [formId]);

  useEffect(() => { store.exportTableFilters.setColumns(columns); }, [formId, columns]);
  useEffect(() => {
    store.exportTableFilters.setThinData(data);
    store.exportTableFilters.setZeroRows(data.length <= 0 ? true : false);
  }, [formId, data]);

  return (
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
        overflow: "auto"
        // padding: "0px 20px 0 24px",
      }}
    >

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          // overflow: "scroll",
          flexGrow: 1,
          flexShrink: 1,
          // height:"70vh"
        }}
      >
        {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={"80%"} /> : <ThinTable columns={columnsConfig} data={data} print="false" />}
      </div>
    </div >
  )
});

const FormDataAggregatePage = observer(() => {
  const [forms, setForms] = useState<{ id: string; name: string; }[]>([]);
  const [loading, setLoading] = useState(false);
  const { formId } = useParams();
  const classes = useStyles();
  const { push } = useHistory();
  const [state, FormIdFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, apply: true, reset: false }, () => formId ? [formId] : []);
  const store = useStore();
  useEffect(() => { if (!formId) { putAsync(relay, { type: 'replace', payload: [] }); } }, [formId]);
  useEffect(() => { if (!client.client) { client(); } }, [client]);
  const disabled = useMemo(() => !state.length || state[0] === formId, [state, formId]);
  const applyHandler = useCallback(() => {
    if (state.length) { push(`/${__SECTION}/${state[0]}`); putAsync(relay, { type: "toggle", payload: false }); }
  }, [state]);
  useEffect(() => {
    if (client.client) {
      setLoading(true);
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_FORMS, variables: { propertyList: store.projectInfo.currentProject?.properties.map(({ id }) => id) || [] }, errorPolicy: "all" });
      const observable = query.subscribe(({ data, loading, errors }) => {
        if (errors) { console.error(errors); setLoading(false); }
        else if (!loading) {
          setForms(() => (data['forms']?.map(({ id, form_config: { name } }) => ({ id, name })) || []));
          setLoading(false);
        }
      });
      return () => { observable.unsubscribe(); };
    }
  }, []);
  return (<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
    <div style={{ display: "flex", flexShrink: 1 }}>
      <div className={classes.formDataDiv}>
        <div style={{ display: "flex", flexShrink: 1 }}>
          <FormIdFilter
            options={forms}
            Render={RadioButton}
            callback={applyHandler}
            disabled={disabled}
            title={<SelectedState title="Form" state={state} filterType="form" />}
            formData={true}
          />
        </div>
        <div className={classes.arrowDiv}>
          <img src={RightArrow} height={20} width={20} alt="previous_page" />
        </div>
        <div className={classes.formDiv}>
          <div className={classes.formSpan}>{formId ? forms.find(({ id }) => id === formId)?.name : "Please select a Form"}</div>
        </div>
      </div>
    </div>
    <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1, overflow: "scroll" }}>
      {!loading && !formId && (
        <div className={classes.initialMessageDiv}>
          View and export data for various forms
        </div>
      )
      }
      {!loading && formId && (
        <FormDataAggregate
          formId={formId}
        />
      )}
    </div>
  </div>);
});

export default FormDataAggregatePage;
