import React, { memo, Dispatch, ReducerAction } from 'react';
import { TextField, } from '@material-ui/core';

export interface IDateTimeInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: string;
  type: string;
  label: string;
  disabled?: boolean;
};

const DateTimeInput = ({ field, dispatch, state, type, label, disabled }: IDateTimeInputProps) =>
  (
    <TextField
      label={label}
      type={type}
      defaultValue={state}
      disabled={!!disabled}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={type === "time" ? { step: 300 } : undefined}
      onChange={(event) => {
        const input = event.target.value;
        dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input } });
      }}
    />
  );

export default memo(DateTimeInput);
