import React, { useState, useEffect } from "react";
import useStyles from './ProjectSelectionStyles';
import { ExpansionPanel, ExpansionPanelDetails, Grid, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import { useHistory } from 'react-router';
// import {Project_Placeholder_Image} from '../../assets/images/projectSelection';
import projectPlaceholder from '../../assets/images/projectSelection/Project_Placeholder_Image.svg';
// import expand from '../../assets/images/projectSelection/Expand_Icon.svg';
// import collapse from '../../assets/images/projectSelection/Collapse_Icon.svg';
import arrow from '../../assets/images/projectSelection/Arrow.svg';
import logo from '../../assets/images/projectSelection/fullLogoWhit.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import MenuComponent from "../../components/menuComponent/MenuComponent";
import { putAsync } from "csp-with-ts/lib";
import { reaction } from "mobx";
import { useMediaQuery } from 'react-responsive';
import { LG_DEVICE_QUERY } from '../../utils/responsive';
import styled, { css } from 'styled-components';

/* Shadow wrapper component for projects container div.
   To switch the CSS shadow effect based on projects container div scroll position.*/
const Shadow = styled.div`
  position: relative;
  ${props =>
    props.showStart
      ? css`
          ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 50px;
            z-index: 999;
            background: linear-gradient(
              to top,
              rgba(255, 255, 255, 0),
              rgb(47 62 108)
            );
          }
        `
      : ''}
  ${props =>
    props.showEnd
      ? css`
          ::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            z-index: 999;
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0),
              rgb(47 62 108)
            );
          }
        `
      : ''}
`;

export function ProjectSelection() {
  // States
  const [hover, setHover] = useState<boolean>(false);
  const [showStart, setShowStart] = useState<boolean>(false);
  const [showEnd, setShowEnd] = useState<boolean>(false);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles({ hover });
  const store = useStore();
  const [value, setValue] = useState('Select a Project');
  const [expanded, setExpanded] = useState("");
  const history = useHistory();

  const isDesktop = useMediaQuery(LG_DEVICE_QUERY);

  // Inline Styles
  const custNameStyle = { fontWeight: 600, fontSize: "16px" }
  const statusStyle = { display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", marginTop: "30px", fontSize: "14px" }
  const projNameStyle = { fontWeight: 700, fontSize: isDesktop ? "14px" : "12px" }
  const propButtonStyle = { padding: "8px 10px", color: "#005eff", fontSize: "14px", fontWeight: 700 }

  // *temp handler for bypassinf property selection
  const handleChange = (project: { id?: string; name?: any; properties?: any[]; }) => {
    setValue(project.name);
    store.projectInfo.setProject(project.id);
    store.summary.setOpen(false);
    history.push('/');
  };

  // Populates projects and their properties into store
  useEffect(() => {
    if (store.projectInfo.projects.length === 0) {
      putAsync(store.channel, { prop: 'projects', path: '/projectInfo', action: 'getProjects' });
    }
    /* else if (store.projectInfo.currentProject) { history.goBack(); } */
  }, []);
  useEffect(() => reaction(() => store.projectInfo.currentProject, () => { history.push('/'); }), []);

  //onScroll function for projects container scroll view.
  const onScroll = () => {
    const { scrollHeight = 0, scrollTop = 0, offsetHeight = 0 } = scrollRef.current || { scrollHeight: 0, scrollTop: 0, offsetHeight: 0 };
    setShowStart(scrollTop > 0);
    setShowEnd(((scrollTop + offsetHeight) < scrollHeight));
  };

  //Effect that runs everytime projects container is scrolled.
  useEffect(() => {
    onScroll();
    const node: any = scrollRef.current;
    node.addEventListener('scroll', onScroll);
    return () => {
      node.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (store.projectInfo.currentProject) { store.projectInfo.setProject(); }
  }, []);
  //Initial one-time render of the shadow end effect depending on the project items & screen size.
  useEffect(() => {
    if (!store.projectInfo.loading) {
      if (store.responsiveUtils.currentViewport.isLg && store.projectInfo.projects.length >= 5) { setShowEnd(true); }
      if (!store.responsiveUtils.currentViewport.isLg && store.projectInfo.projects.length >= 3) { setShowEnd(true); }
    }
  }, [store.projectInfo.projects.length])

  return useObserver(() => (
    <div className={classes.projectSelectionContainer}>
      <div className={classes.fbtLogo} style={{ width: store.responsiveUtils.currentViewport.isLg ? "80%" : "100%", left: store.responsiveUtils.currentViewport.isLg ? "10%" : "0px" }}>
        <img src={logo} width="150px" height="30px" alt="logo" style={{ paddingTop: "8px" }} />
        <MenuComponent />
      </div>
      <div className={classes.root}>
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.mainContainer : classes.mainContainerSm} >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <div style={{ textAlign: "center", position: "relative" }}>
              {!store.projectInfo.loading ? <img alt="image"
                onError={function defaultImage(img) {
                  img.currentTarget.onerror = null;
                  img.currentTarget.src = projectPlaceholder
                }}
                src={!!store.projectInfo && store.projectInfo.projects.length !== 0 && store.projectInfo.projects[0].builder_image !== null ? store.projectInfo.projects[0].builder_image : projectPlaceholder} width="64px" height="64px" /> :
                <Skeleton variant="text" className={classes.loadImg} />}
              <Typography className={classes.custTitle} style={custNameStyle} component="div">
                {!store.projectInfo.loading ? store.auth.customerName :
                  <Skeleton variant="text" className={classes.loadTitle} />}
              </Typography>
            </div>
          </div>
          <div className={classes.statusTitle} style={statusStyle}>
            <div>
              {value}
            </div>
          </div>
          <Shadow showEnd={showEnd} showStart={showStart}>
            <div className={store.responsiveUtils.currentViewport.isLg ? classes.projectContainer : classes.projectContainerSm} ref={scrollRef}>
              {
                store.projectInfo.projects.map((project: { id: string, name: string, project_image: string, loading: boolean, properties: Array<any> }) => {
                  return (
                    <ExpansionPanel square expanded={expanded === project.id} className={store.responsiveUtils.currentViewport.isLg ? classes.exPanel : classes.exPanelSm} key={project.id}>
                      <div className={classes.projectItem}
                        onMouseOver={e => { setHover(true) }}
                        onMouseLeave={e => setHover(false)}
                        // style={{ zIndex: 1000 }}
                        onClick={project.loading ? () => { } : () => handleChange(project)}>
                        <Grid item xs={7} className={classes.myItemA}>
                          {/* <img src="https://s3.ap-south-1.amazonaws.com/com.fbt.assets/customer_projects/AparnaOneProjectImage.jpg" width="64px" height="64px" style={{marginRight: "20px"}}/> */}
                          <div className={classes.projImg}>
                            {/* {!project.loading ? <img src={project.project_image ? project.project_image : projectPlaceholder} alt="project-img"/> : <Skeleton variant="rect" className={classes.loadImg} />} */}
                            <img className={store.projectInfo.projects[0].builder_image ? classes.homeButton : classes.homeButtonWOimg}
                              onError={function defaultImage(img) {
                                img.currentTarget.onerror = null;
                                img.currentTarget.src = projectPlaceholder
                              }}
                              src={project.project_image ? project.project_image : projectPlaceholder}
                              height="64px" width="65px"
                              alt="Builder" />
                          </div>
                          {/* JSX attribute) React.DOMAttributes<HTMLImageElement>.onError?: ((event: React.SyntheticEvent<HTMLImageElement, Event>) => void) | undefined                  */}
                          <Typography className={classes.projFont} style={projNameStyle} component="div">
                            {!project.loading ? project.name.toUpperCase() : <Skeleton variant="text" width="150px" height="20px" />}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={7} className={classes.myItemA}> */}
                        <span className={classes.myItemC}></span>
                      </div>
                      {
                        project.properties.length > 0 ?
                          project.properties.map((property) => {
                            return (
                              <ExpansionPanelDetails className={classes.propertyItem} key={property.id}>
                                <Grid container >
                                  <Grid item xs={7} className={classes.myItemA}>
                                    <Typography className={classes.propFont} style={{ fontWeight: 500 }}>
                                      {property.name.toUpperCase()}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5} className={classes.myItemC}>
                                    <div style={propButtonStyle}>
                                      <img src={arrow} alt="arrow" />
                                    </div>
                                  </Grid>
                                </Grid>
                              </ExpansionPanelDetails>
                            )
                          }) :
                          <ExpansionPanelDetails className={classes.propertyItem}>
                            <Grid>
                              <Grid item xs={12} className={classes.myItemA}>
                                <Typography className={classes.propFont} style={{ fontWeight: 500 }}>
                                  No Phase Assigned To You
                                </Typography>
                              </Grid>
                            </Grid>
                          </ExpansionPanelDetails>
                      }
                    </ExpansionPanel>
                  )
                })
              }
            </div>
          </Shadow>
        </div>
      </div>
    </div>
  ));
}
