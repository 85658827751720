import React, { useEffect, useState, useMemo, memo } from "react";
import { useLocation, useHistory } from "react-router";
import client from "../../utils/apolloClient";
import DropDown from "./DropDownPage"
import { useSingleValueURLParam } from "../../utils/hooks";
import FilterIcon from "../../assets/images/FilterSmall.svg"
import ResetIcon from "../../assets/images/infoPanel/CloseIcon.svg";
import { useStore } from "../../models/ProvideModel";
import useStyles from '../fatTable/FatTableStyle'
import { columns as columnss } from "../../pages/exportSnagsPage/ExportSnagsPage"
import { columns as exportTaskColumns } from "../../pages/exportTasksPage/ExportTasksPage"
import { UnitPerBlockFatcolumns } from "../../containers/unitInfo/UnitPerBlockFat";
import { UnitInfoPagecolumns } from "../../pages/unitInfoPage/UnitInfoPage";
import { CurrentStepcolumns } from "../../containers/stepInfo/StepPerUnitPerActivity"
import { useObserver } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { reaction } from "mobx";

export interface IQueryFilterParams {
  where: Record<string, any>;
  order_by: { [k: string]: "asc" | "desc" }[];
}
interface IQueryPageParams {
  limit: number;
  offset: number;
}

export type IQueryParams = IQueryFilterParams & Partial<IQueryPageParams>;

const ByFilter = () => {
  const [filterParams, setfilterParams] = useState<any>()
  const [checkedValues, setCheckedValues] = React.useState<String[]>([])
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const store = useStore();
  const [headersData, setHeadersData] = React.useState<any>([])

  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const columns = store.params.screen === "fullscreen" && store.params.phase === "finishing" ?
    store.params.section === "legacy" ? UnitPerBlockFatcolumns : UnitInfoPagecolumns :
    (store.params.section === "snags" || store.params.section === "tasks") ? store.params.section === "snags" ? columnss : exportTaskColumns : store.params.screen === "misc" ? CurrentStepcolumns : UnitInfoPagecolumns
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const { push } = useHistory();
  const [print] = useSingleValueURLParam("view", "normal", searchParam);
  const classes = useStyles({ print });

  const resetHandler = (id: string, key: string) => {
    switch (key) {
      case "resetItem":
        searchParam.delete(id);
        push({ ...location, search: searchParam.toString() });
        break;
      case "resetAllItems":
        columns.map(({ id, name }) => {
          return { itemSelected: searchParam.delete(id), name, id }
        })
        push({ ...location, search: searchParam.toString() });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setDataLoading(true)
  }, []);


  const setParticularid = (id: any | null) => {
    if (!checkedValues.includes(id)) {
      setCheckedValues([...checkedValues, id])
    }
    else {
      const newAsc = checkedValues.filter(element => element !== id)
      setCheckedValues(newAsc)
    }
  }

  useEffect(() => {
    setHeadersData(store.exportTableFilters.columnConfig)
    if (!searchParam.toString() && filterParams?.filterByList?.length) {
      setfilterParams([]);
    }
  }, [searchParam, dataLoading]);

  useEffect(() => reaction(() => ({ columnConfigloading: store.exportTableFilters.columnConfigloading, searchParam: searchParam, dataLoading: dataLoading }), () => {
    const filterByList: any[] = []
    if (store.exportTableFilters.columnConfig.length > 0 || dataLoading) {
      store.exportTableFilters.columnConfig.forEach(function ({ id, name }) {
        if (searchParam.getAll(id).length) {
          filterByList.push({ itemSelected: searchParam.getAll(id), name, id })
        }
      })
      setfilterParams({ filterByList });
    }
  }), [searchParam, dataLoading]);

  const { isLg } = store.responsiveUtils.currentViewport;

  const filterBySnagAge = () => {
    searchParam.delete('status');
    const newAsc = searchParam.getAll('sort').filter(el => el !== "created_at");
    searchParam.delete('sort');
    newAsc.forEach(val => { searchParam.append('sort', val); });
    searchParam.append('status', 'open');
    searchParam.append('status', 'for review');
    searchParam.append('sort', 'created_at');
    push({ ...location, search: searchParam.toString() });
  }
  const filterFormEdit = () => {
    searchParam.delete('form_data');
    searchParam.append('form_data', 'edit');
    push({ ...location, search: searchParam.toString() });
  };

  return useObserver(() => (
    <div className={classes.infoPanelRoot}>
      {store.params.section === "snags" ? <Button
        variant="contained"
        color="primary"
        size="small"
        className={store.responsiveUtils.currentViewport.isLg ? classes.button : classes.buttonSm}
        onClick={filterBySnagAge}
      >
        Sort snag Age in Asc Order
      </Button> : null}
      {store.params.section === "unit-act-info" ?
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={store.responsiveUtils.currentViewport.isLg ? classes.button : classes.buttonSm}
          onClick={filterFormEdit}
        >
          Show rows where I can edit a form
       </Button> : null}
      <div className={classes.titleLable} style={{ paddingTop: "1rem" }}>
        <div className={classes.filterIcondiv}>
          <img src={FilterIcon} style={{ width: "17px" }} />
          <label className={classes.filterBy}>filter By</label>
        </div>
        {filterParams?.filterByList?.length ? <div onClick={() => resetHandler("id", "resetAllItems")} className={classes.resetAll}>Reset</div> : null}
      </div>
      {
        filterParams?.filterByList?.length ?
          filterParams?.filterByList?.map(({ itemSelected, name, id }: any) => {
            return <div>
              <div key={`${id}-${name}`} style={{ display: "flex", justifyContent: "space-between" }}><div key={name} className={classes.filterRectangle}><span className={classes.columnText}>{name} ({
                Date.parse(itemSelected[0]) && itemSelected[0].length > 11 ? (itemSelected?.length - 1).toLocaleString('en-GB', { minimumIntegerDigits: 2 }) :
                  itemSelected?.length.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}) </span>
                <div className={classes.showAndHide} onClick={() => setParticularid(id)}>{checkedValues.includes(id) ? "show" : "hide"}</div>
              </div>
                <div style={{ display: "flex", height: "32px", width: "14%", background: "#f0efef", margin: "4px 0px 4px 0px", justifyContent: "center" }}>
                  <img src={ResetIcon} alt="Reset" onClick={() => resetHandler(id, "resetItem")} style={{ cursor: "pointer" }} />
                </div>
              </div>
              {store.exportTableFilters.columnConfig.length > 0 &&
                <DropDown columns={store.exportTableFilters.columnConfig} itemSelected={itemSelected} name={name} id={id} open={checkedValues.includes(id) ? false : true}></DropDown>
              }
            </div>
          })
          :
          <div className={classes.emptyFilter}>
            <span className={classes.noFilter}>No Filters Applied</span>
          </div>
      }
    </div >
  ));
};

export default memo(ByFilter);
