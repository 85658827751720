import React, { useCallback, useEffect, MouseEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from "./CommonPopUpStyles"
import { Zoom } from '@material-ui/core';
import SummaryTable from '../SummaryTable';
import SummaryDetails from '../SummaryDetails';
import PeopleInvolved from '../PeopleInvolved';
import VerticalScrollbar from '../../customScrollbar/VerticalScrollbar';
import { useStore } from '../../../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import AvtivityOverview from '../../../containers/ActivityOverview'
const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

function SummaryPopUp({ open, unitId, pourType, phase }: any) {
  const classes = useStyles();
  const store = useStore();

  useEffect(() => {
    if (unitId) {
      store.summary.setUnit(unitId, phase);
    }
  }, [phase, store, store.summary, unitId]);

  const handleClose = useCallback((ev: MouseEvent) => { store.summary.setOpen(false) }, [store.summary]);
  return useObserver(() => store.summary.brick ?
    (<div className={classes.root}>
      <Dialog fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        transitionDuration={300}
        className={phase === "structures" && store.params.spaceType === "tower" ? classes.dialogBox : classes.dialogBoxLg}
      >

        {!(store.summary.brick.phase === 'structures' && store.summary.brick.spaceType === 'tower') ? (
          <>
            <div className={classes.mainDivFinishing}>
              <div style={{ position: "absolute", top: "20px", right: "24px", cursor: "pointer" }}>
                <div className={classes.changeImage}>
                  <div onClick={handleClose} className={classes.backDiv}><span className={classes.backBtn}>Close</span></div>
                </div>
              </div>
              <AvtivityOverview
                classes={classes}
                pourType={store.summary.brick.pourType}
                phase={store.summary.brick.phase}
                spaceType={store.summary.brick.spaceType}
                loading={store.summary.loading}
              />
            </div>
          </>
        ) : (
            <>
              <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <div className={classes.header}>
                    <Typography className={classes.unitSnapshot}>
                      {" Pour Snap Shot"}
                    </Typography>
                    <div className={classes.unitTitle}>
                      <span>{store.summary.towerDetails.towerName + ' | ' + store.summary.brick.name.split(" ")[0] + " " +
                        store.summary.brick.name.split(" ")[1].toLocaleString('en-GB', { minimumIntegerDigits: 2 }) + ' | ' +
                        store.summary.brick.name.split(" ")[2] + " " + store.summary.brick.name.split(" ")[3]}</span>
                    </div>
                  </div>
                  <div style={{ marginRight: "20px", display: "flex", position: "absolute", right: 16, cursor: "pointer" }} onClick={handleClose}>
                    {/* <div className={classes.changeImage}><span></span></div> */}
                    <div className={classes.changeImage}>
                      <div onClick={handleClose} className={classes.backDiv}><span className={classes.backBtn}>Close</span></div>
                    </div>
                  </div>
                </Toolbar>
              </AppBar>

              <div className={classes.mainDiv}>
                <div className={classes.leftDivStructurs}>
                  <VerticalScrollbar>
                    <SummaryDetails
                      classes={classes}
                      data={store.summary.brick}
                      totalDelay={store.summary.totalDelay}
                      loading={store.summary.loading}
                      phase={store.summary.brick.phase}
                    />
                    {store.summary.brick.pourType === "conventional" ?
                      <SummaryTable classes={classes}
                        loading={store.summary.loading} /> : null}

                    <AvtivityOverview
                      classes={classes}
                      pourType={store.summary.brick.pourType}
                      phase={store.summary.brick.phase}
                      spaceType={store.summary.brick.spaceType}
                      loading={store.summary.loading}
                    />
                    {/* Pepole involved is included in activity list  */}
                    {store.summary.brick.pourType === "conventional" ? <PeopleInvolved classes={classes}
                      peopleInvolved={store.summary.people}
                      loading={store.summary.loading} /> : null}

                  </VerticalScrollbar>
                </div>
              </div>
            </>
          )}
      </Dialog>
    </div>
    ) : null)
}

export default React.memo(SummaryPopUp)
