import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: "Source Sans Pro, sans-serif",
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    accordion__icon: {
        marginLeft: "auto",
        transition: "transform 0.6s ease"
    },
    /* Style to rotate icon when state is active */
    rotate: {
        transform: "rotate(180deg)"
    },
    formControl: {
        width: "100%",
        cursor: "pointer"
    },
    input: {
        cursor: "pointer !important"
    },
    selectMenu: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #055eff",
        alignItems: "center",
        whiteSpace: "nowrap",
        cursor: "pointer",
        width: "100%",
    },
    ellipsisContent: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#585858",
        fontFamily: "Source Sans Pro, sans-serif",
    },
    filterLenght: {
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.29,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#585858",
        fontFamily: "Source Sans Pro, sans-serif",
    },
    selectInput: (props: { sortSelected: string }) => ({
        fontFamily: "Source Sans Pro, sans-serif !important",
        fontSize: "14px",
        cursor: "pointer",
        width: "100%",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "-1px",
        "& input": {
            color: "#585858",
            fontFamily: "Source Sans Pro, sans-serif !important",
            fontSize: "14px",
            cursor: "pointer",
            width: (props.sortSelected === "ascending_pre" || props.sortSelected === "descending_pre") ? "75%" : "72%",
            textTransform: "capitalize",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
            marginLeft: (props.sortSelected === "ascending" || props.sortSelected === "descending" || props.sortSelected === "ascending_pre" || props.sortSelected === "descending_pre") ? 20 : 0
        },
        "& label": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
            fontSize: "14px",
            height: "17px"
        }
    }),
    arrowImg: {
        position: "absolute",
        // left: "60px",
        right: "0px",
        top: "2px",
        cursor: "pointer"
    },
    typeButton: {
        minWidth: "100px", width: "100%", border: "none",
        "&:after": { border: "none" },
    },
    sortIcon: {
        position: "relative",
        alignSelf: "baseline",
        top: "8px"
    },
    popperOuterContainer: {
        maxHeight: "63vh",
        zIndex: 1300,
        maxWidth: "280px",
        minWidth: "280px"
    },
    popperOuterContainerSm: {
        maxHeight: "80vh",
        zIndex: 1300,
        maxWidth: "220px",
        minWidth: "220px"
    },
    popperInnerContainer: {
        maxHeight: "63vh",
        overflow: "auto",
        position: "absolute",
        top: "12px",
        maxWidth: "280px",
        minWidth: "280px"
    },
    popperInnerContainerSm: {
        maxHeight: "70vh",
        overflow: "scroll",
        position: "absolute",
        top: "-125px",
        maxWidth: "220px",
        minWidth: "220px"
    },
    popperInnerContainerFs: {
        maxHeight: "70vh",
        overflow: "scroll",
        position: "absolute",
        top: "-83px",
        maxWidth: "220px",
        minWidth: "220px"
    },
    tabNameMainDiv: {
        backgroundSize: "90% 2px, auto",
        backgroundColor: "rgb(244, 244, 244)",
        backgroundImage: "linear-gradient(#005EFF, #005EFF)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        padding: "0px 10px",
        display: "flex",
        flexDirection: "column"
    },
    tabnameDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    titleDiv: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        textTransform: "capitalize",
        overflow: "hidden"
    }
})
);

export default useStyles;
