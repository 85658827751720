import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  headerDiv: {
    width: "45px",
    display: "flex",
    textAlignLast: "end"
  },
  buttonToggleGroup: {
    borderRadius: "0px !important",
    border: "solid 1px #efefef !important",
    height: "32px",
    width: "32px !important",
    "&:hover": {
      backgroundColor: "#ffffff"
    },
    "&$selected": {
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.12) !important",
      },
    }
  },
  buttonToggleRight: {
    borderRadius: "0px !important",
    border: "solid 1px #efefef !important",
    marginLeft: "20px !important",
    height: "32px",
    width: "32px !important",
    "&:hover": {
      backgroundColor: "#005eff"
    },
    "&$selected": {
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.12) !important"
      },
    }
  },
  sort: {
    width: "90px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
    textTransform: "uppercase",
  },
  floor: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    alignSelf: "center",
    marginLeft: "14px"
  },
  floorDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    margin: "12px 0 12px 21px"
  },
  sortRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
    border: "1px solid #005eff",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage: 'radial-gradient(#2f3e6c,#2f3e6c 28%,transparent 32%)',
      content: '""',
    },
  },
}));

export default useStyles;
