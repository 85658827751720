import React, { memo } from 'react';
import { Link } from '@material-ui/core';
import { IChan, putAsync } from 'csp-with-ts';
import { IUseMux, useGetState } from './UseAccordionHook';
import { useObserver } from 'mobx-react-lite';
import useStyles from "./FilterStyles";

export interface ISelectAll<T extends Record<string, any>, K extends keyof T> {
    options: T[];
    broadcast: IChan<T[K][]>;
    relay: IChan<IUseMux<T, K>>;
};
const SelectAll = memo(({ options, broadcast, relay }: ISelectAll<any, any>) => {
    const classes = useStyles();
    const state: any = useGetState(broadcast);
    const sel = !!state ? options.length > state.length ? false :
        options.every(({ id }) => state.includes(id)) : false;
    const toggleSelect = () => {
        options.forEach(({ id }) => {
            if (!state.includes(id)) {
                putAsync(relay, { type: 'select', payload: id })
            }
        })
        if (options.every(({ id }) => state.includes(id))) {
            options.forEach(({ id }) => { putAsync(relay, { type: 'select', payload: id }) })
        }
    };
    return useObserver(() => (
        <Link className={classes.link} onClick={toggleSelect}>{sel ? "Deselect All" : "Select All"}</Link>
    ));
});
export default memo(SelectAll);
