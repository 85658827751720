import { types, flow, getRoot, Instance } from 'mobx-state-tree';
import { LateStoreModel } from './DataStore';
import { GET_MENU_DETAILS } from '../utils/queries';
import { sortingStrategyName } from '../utils/utils';

export interface IPhase {
    id: string,
    name: string
}

export interface ISpaceType {
    name: string,
    id: string
}

function mapOrder(array, order, key?) {
    array.sort(function (a, b) {
        var A = key === undefined ? a : a[key], B = key === undefined ? b : b[key];
        if (order.indexOf(A) > order.indexOf(B) || order.indexOf(A) === -1 || order.indexOf(B) === -1) {
            return 1;
        } else {
            return -1;
        }
    });
    return array;
};

export const BlockDetails = types.model({
    id: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
})
export const MenuModel = types.model({
    menus: types.frozen<object>(),
    loading: false,
    currentSelection: types.maybeNull(types.string),
    selected: types.maybeNull(types.boolean),
    blocks: types.array(BlockDetails),
})
    .views(self => ({
        get getDistinctMenu() {
            if (!!self.menus) {
                let filteredArray: any[] = [], temp: object = {}, existing: string[] = [];
                for (let { phase, spaceType, spaceTypeName, reports } of self.menus as any[]) {
                    if (existing.includes(phase)) {
                        if (temp[phase][spaceType]) { temp[phase][spaceType].report.push(...reports); }
                        else { temp[phase][spaceType] = { spaceTypeName, report: [...reports] }; }
                    }
                    else {
                        existing.push(phase);
                        temp[phase] = { [spaceType]: { spaceTypeName, report: [...reports] } };
                    }
                }
                for (let [phase, spaceTypes] of Object.entries(temp)) {
                    let miniArr: any[] = [];
                    for (let [spaceType, { spaceTypeName, report }] of Object.entries<{ [K: string]: { spaceTypeName: string; report: any[]; } }>(spaceTypes)) {
                        miniArr.push({ phase, spaceType, report, spaceTypeName });
                    }
                    filteredArray.push({ phase, spaceTypes: miniArr });
                }
                var item_array, item_order, ordered_array;
                item_array = filteredArray
                item_order = ["structures", "finishing"];
                ordered_array = mapOrder(item_array, item_order, 'phase');
                return ordered_array
            }
            return null;
        },
        get getPropertyList() {
            return (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.properties.map(({ name }) => name);
        }
    }))
    .actions(self => ({
        getMenu: flow(function* () {
            try {
                self.loading = true;
                const propertyList = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.properties.map(({ id }) => id);
                const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_MENU_DETAILS, {
                    variables: { propertyList },
                    fetchPolicy: 'network-only'
                })
                self.menus = data.phaseSpace as any[];

                /** Commenting below code as below query is merged with GET_MENU_DETAILS */
                // const blockData = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_TOWER_DROPDOWN, {
                //     variables: { propertyList },
                //     fetchPolicy: 'network-only'
                // })
                self.blocks = data.blocks.sort(sortingStrategyName);
                let sts: any[] = [], phases: string[] = [];
                for (let { spaceType, spaceTypeName, phase } of data.phaseSpace) {
                    if (!sts.map(({ id }) => id).includes(spaceType.toLowerCase())) { sts.push({ id: spaceType.toLowerCase(), name: spaceTypeName }); }
                    if (!phases.includes(phase)) { phases.push(phase); }
                }
                var item_array, item_order, ordered_array, sts_array, sts_order, sts_ordered_array;
                item_array = phases; sts_array = sts
                item_order = ["structures", "finishing"]; sts_order = ["tower", "tca", "eca"]
                ordered_array = mapOrder(item_array, item_order); sts_ordered_array = mapOrder(sts_array, sts_order, 'id');
                (getRoot(self) as Instance<typeof LateStoreModel>).params.setSpaceTypes(sts_ordered_array);
                (getRoot(self) as Instance<typeof LateStoreModel>).params.setPhases(ordered_array);
            } catch (error) {
                console.error(error);
            } finally {
                self.loading = false;
                self.selected = false;
            }
        })
    }));

export type IMenuModel = typeof MenuModel.Type
