import React, { useState, memo } from "react";
import FsLightbox from "fslightbox-react";
import curroptImgPlaceholder from "../assets/images/table/Image Corrupted.svg";
import imgPlaceholder from "../assets/images/table/Image Placeholder.svg";
import "../components/switchTowersDD/animation.scss";

const baseURL = process.env.REACT_APP_IMAGE_BASE_URL;
const SnagImages = (props) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const imgName: string = props.cell.value;
  const [error, setError] = useState<boolean>(false)

  const loadCorreptImages = (event) => {
    setError(true)
    event.currentTarget.onError = null;
    event.currentTarget.src = curroptImgPlaceholder;
  };
  return !imgName ? (
    <div style={{ display: "flex", justifyContent: "center", }}>
      <img
        src={imgPlaceholder}
        alt={"Image Placeholder.svg"}
      />
    </div>
  ) : (
      <div>
        <div style={{ display: "flex", justifyContent: "center", cursor:"pointer" }}>
          <img
            src={`${baseURL}small/${imgName}`}
            alt={imgName}
            onClick={() => !error && setToggle(!toggle)}
            onError={loadCorreptImages}
            style={{maxHeight: 150, maxWidth: 150}}
          />
        </div>
        <FsLightbox
          key={imgName}
          toggler={toggle}
          sources={[`${baseURL}large/${imgName}`]}
          type="image"
        />
      </div>
    );
};

export default memo(SnagImages);
