import React, { useState, memo } from "react";
import moment from 'moment';
import FsLightbox from "fslightbox-react";
import curroptImgPlaceholder from "../assets/images/table/Image Corrupted.svg";
import imgPlaceholder from "../assets/images/table/Image Placeholder.svg";
import "../components/switchTowersDD/animation.scss";
import { Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    imageStyle: { display: "flex", justifyContent: "start", cursor: "pointer", height: "100%" },
    scrollMore: { position: "initial", top: 0, right: "16px", textAlign: "right", color: "#005eff", fontSize: "11px", '@media print': { display: "none" } },
    snagsImage: { maxHeight: 105, maxWidth: 105 ,padding:"5px", "&:hover": {  maxWidth: 110 ,padding:"0px"}}
}));

const baseURL = process.env.REACT_APP_IMAGE_BASE_URL;
const CommentCell = (props) => {
    const [toggle, setToggle] = useState<boolean>(false);
    const [imageurl, setImageUrl] = useState("")
    const classes = useStyles();
    const corruptedImage = (event) => {
        event.currentTarget.onError = null;
        event.currentTarget.src = curroptImgPlaceholder;
    };

    if (!props.row.original[props.column.value]) {
        return (<div> — </div>)
    }

    const handleImage = async (comment) => {
        await setImageUrl(comment.attachment)
        setToggle(!toggle)
    }

    return (
        <div>
            {props.row.original[props.column.value].length > 1 ?
                <div className={classes.scrollMore}>
                    {props.row.original[props.column.value].length} comments
                </div> :
                null}
            {props.row.original[props.column.value].map((comment, index) => {
                return (
                    <div key={index} style={{ height: "100%" }}>
                        <div key={index} style={{ height: "100%" }}>
                            {comment.attachment ? <div>
                                <div className={classes.imageStyle} key={index}>
                                    <img
                                        key={comment.attachment}
                                        src={`${baseURL}small/${comment.attachment}`}
                                        alt={comment.attachment}
                                        onClick={() => handleImage(comment)}
                                        onError={corruptedImage}
                                        className={classes.snagsImage}
                                    />
                                </div>
                            </div> : null}
                            <div>
                                <span style={{ fontSize: "15px", marginTop: "10px" }}>{comment.comment}</span><br />
                                {comment.name} | {moment(comment.time_stamp).format("D MMM YYYY")} | {moment(comment.time_stamp).format("HH:MM")}
                            </div>
                        </div>
                        <div style={{ padding: "10px 0px" }}><Divider style={{ height: "2px" }} /></div>
                    </div>
                )
            })}
            <FsLightbox
                key={imageurl}
                toggler={toggle}
                sources={[`${baseURL}large/${imageurl}`]}
                type="image"
            />
        </div>)
}

export default CommentCell
