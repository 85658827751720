import React, { useEffect, useState, useReducer, useMemo, MemoExoticComponent, FunctionComponent } from 'react';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from "react-router";
import client from "../../utils/apolloClient";
import { GET_FORM_DATA_AND_FIELD_PERMISSIONS } from '../../utils/queries';
import { formEditReducer, transformDate, transformTime, transformDateTime, transformPercent, transformPhoto } from '../../utils/utils';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import { useStore } from '../../models/ProvideModel';
import GenericInput, { IGenericInputProps } from '../../components/FormInputs/GenericInput';
import DateTimeInput, { IDateTimeInputProps } from '../../components/FormInputs/DateTimeInput';
import FormDropdown, { IFormDropdownInputProps } from '../../components/filter/FormDropdown';
import useStyles from './FormDataWritePageStyles';
import ImagePicker, { IImagePickerProps } from '../../components/FormInputs/ImagePicker';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { updateForm } from '../../api/transactionServer';
import GenericModal from '../../containers/GenericModal';
import { getEnv } from 'mobx-state-tree';

export interface IFieldParams {
  id: string;
  name: string;
  isMandatory: boolean;
  action: 'R' | 'W';
  type: 'photo' | 'date' | 'time' | 'datetime' | 'dropdown' | 'numeric' | 'percent' | 'text';
  prefillData: string[];
  description?: string;
};

/* Modal Shenanigans */
enum ModalState {
  CLOSED = "CLOSED",
  SUBMIT_OPEN = "SUBMIT_OPEN",
  SUBMIT_ERROR = "SUBMIT_ERROR",
  SUBMIT_SUCCESS = "SUBMIT_SUCCESS",
  CANCEL_OPEN = "CANCEL_OPEN"
};

enum ModalActions {
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  CHANGE_MODAL_STATE = "CHANGE_MODAL_STATE",
  SET_LOADING = "SET_LOADING"
};

enum ModalEffects {
  GO_BACK = "GO_BACK",
  SUBMIT = "SUBMIT"
};

interface IModalAction {
  type: string;
  onClick: { effect?: ModalEffects; action?: ModalActions; }
};

interface IModalConfig {
  [K: string]: {
    state: ModalState;
    isOpen: boolean;
    title: string;
    text: string;
  };
};

interface IModalActionsConfig {
  [K: string]: IModalAction[];
};


const modalsActionsConfig: IModalActionsConfig = {
  [ModalState.CLOSED]: [],
  [ModalState.SUBMIT_OPEN]: [{
    type: "OK",
    onClick: {
      effect: ModalEffects.SUBMIT,
    }
  },
  {
    type: "Cancel",
    onClick: { action: ModalActions.CLOSE_MODAL }
  }],
  [ModalState.SUBMIT_ERROR]: [{
    type: "Close",
    onClick: { action: ModalActions.CLOSE_MODAL }
  }],
  [ModalState.SUBMIT_SUCCESS]: [{
    type: "Yes",
    onClick: { effect: ModalEffects.GO_BACK }
  }, {
    type: "No",
    onClick: { action: ModalActions.CLOSE_MODAL }
  }],
  [ModalState.CANCEL_OPEN]: [{
    type: "Discard",
    onClick: { effect: ModalEffects.GO_BACK }
  }, {
    type: "Close",
    onClick: { action: ModalActions.CLOSE_MODAL }
  }]
}

const modalsConfig: IModalConfig = {
  [ModalState.CLOSED]: {
    state: ModalState.CLOSED,
    isOpen: false,
    title: "",
    text: ""
  },
  [ModalState.SUBMIT_OPEN]: {
    state: ModalState.SUBMIT_OPEN,
    isOpen: true,
    title: "Submit Changes",
    text: "Are you sure you want to submit your changes?",
  },
  [ModalState.SUBMIT_ERROR]: {
    state: ModalState.SUBMIT_ERROR,
    isOpen: true,
    title: "An Error Occurred while attempting to Submit Changes.",
    text: "Error Message: ",
  },
  [ModalState.SUBMIT_SUCCESS]: {
    state: ModalState.SUBMIT_SUCCESS,
    isOpen: true,
    title: "Success!",
    text: "Your changes were submitted successfully! Would you like to go back?",
  },
  [ModalState.CANCEL_OPEN]: {
    state: ModalState.CANCEL_OPEN,
    isOpen: true,
    title: "Discard Changes",
    text: "Do you want to discard your changes and go back to the List of Unit Activities?",
  }
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case ModalActions.SET_LOADING: return { ...state, loading: action.payload.loading || true };
    case ModalActions.CHANGE_MODAL_STATE: return { ...modalsConfig[action.payload.state], text: modalsConfig[action.payload.state].text + (action.payload.message ? `\n${action.payload.message}` : ""), loading: false };
    case ModalActions.CLOSE_MODAL: return { ...modalsConfig[ModalState.CLOSED], loading: false };
    case ModalActions.OPEN_MODAL: return { ...modalsConfig[action.payload.state], loading: false };
    default: return { ...modalsConfig[ModalState.CLOSED], loading: false };
  }
};

/* End of Modal Shenanigans */

const transformMap = {
  'date': { transform: transformDate, Component: DateTimeInput },
  'time': { transform: transformTime, Component: DateTimeInput },
  'datetime': { transform: transformDateTime, Component: DateTimeInput },
  'percent': { transform: transformPercent, Component: GenericInput },
  'numeric': { Component: GenericInput },
  'text': { Component: GenericInput },
  'dropdown': { Component: FormDropdown },
  'photo': { transform: transformPhoto, Component: ImagePicker }
};

const reverseTransform = {
  'date': (val: string) => val.length ? moment(val).format("YYYY-MM-DD") : "",
  'time': (val: string) => val.length ? moment(val).format("HH:mm") : "",
  'datetime': (val: string) => val.length ? moment(val).format("YYYY-MM-DD[T]HH:mm") : ""
};

function sanitizeState(fields: IFieldParams[], state: { [K: string]: any; }) {
  return fields.reduce((acc, { id, type }) => ({ ...acc, [id]: state[id] ? transformMap[type]['transform'] ? transformMap[type]['transform'](state[id]) : state[id] : "" }), {});
}

interface IFormPageTitle {
  formDescription: string;
  unit: string;
  activity: string;
};
// What about resetting state, and persisting to localStorage and finding out if
// Submit should be disabled?
const FormDataWritePage = () => {
  const { unitActivityId, formId } = useParams();
  const { goBack } = useHistory();
  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState<string>();
  const [title, setTitle] = useState<IFormPageTitle>({ formDescription: "", unit: "", activity: "" });
  const [fields, setFields] = useState<IFieldParams[]>([]);
  const [state, dispatch] = useReducer(formEditReducer, { isChanged: false });
  const [modalState, modalDispatch] = useReducer(modalReducer, modalsConfig[ModalState.CLOSED]);
  const disabled = useMemo(() => {
    if (state.isChanged) {
      return fields.reduce((acc, { id, isMandatory, action }) => acc ? acc : (isMandatory && action === "W") ? !state[id].length : false, false);
    }
    else { return true; }
  }, [fields, state]);
  const opAction = useMemo(() => lastUpdated ? lastUpdated === "" ? 'form_create' : 'form_update' : 'form_create', [lastUpdated]);
  const store = useStore();
  const classes = useStyles({ isLg: store.responsiveUtils.currentViewport });
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);

  useEffect(() => {
    if (client.client && formId && unitActivityId) {
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({
          query: GET_FORM_DATA_AND_FIELD_PERMISSIONS,
          variables: { unitActivityId, formId, formJson: { "form_id": formId } }
        }).subscribe(({ data: { formDetails, fieldConfig }, loading }) => {
          if (!loading) {
            setLoading(false);
            const fields = fieldConfig.map(({ field: { id, description, data } }) => ({ id, name: data.label, description, isMandatory: data.isMandatory, type: data['input_type'], prefillData: data['prefill_data'] || [] }));
            if (fieldConfig.length) {
              setTitle({
                formDescription: fieldConfig[0].form.name,
                unit: formDetails[0].unit,
                activity: formDetails[0].activity
              });
            }
            if (formDetails.length === 1) {
              const { permissions, formData } = formDetails[0];
              if (formData.length) {
                setLastUpdated(formData[0].lastUpdated);
                dispatch({ type: 'RESET', payload: fields.reduce((acc, { id, type }) => ({ ...acc, [id]: reverseTransform[type] ? reverseTransform[type](formData[0].data[id] || "") : (formData[0].data[id] || (type === 'photo' ? [] : "")) }), {}) });
              }
              else {
                dispatch({ type: 'RESET', payload: fields.reduce((acc, { id, type, prefillData }) => ({ ...acc, [id]: type === 'photo' ? [] : 'dropdown' ? "" : (prefillData[0] || "") }), {}) });
              }

              setFields(fields.map(({ id, ...rest }) => ({ id, ...rest, action: permissions.action.toLowerCase() === "write" ? "W" : permissions.action.toLowerCase() === "read" ? "R" : (permissions['field_controls'][id]?.action || "W") })));

            }

          }
        });
      return () => { query.unsubscribe(); };
    }
  }, [formId, unitActivityId]);
  const onSubmit = () => {
    let promise: Promise<any> | null = null;
    const payload = {
      "customer_id": store.auth.customerId,
      "opAction": opAction,
      "activity_id": unitActivityId,
      "current_user": store.auth.userId,
      "form_id": formId,
      "form_data": sanitizeState(fields, state)
    };
    modalDispatch({ type: ModalActions.SET_LOADING, payload: { loading: true } });
    if (opAction === 'form_create') { promise = updateForm(payload) }
    else { promise = updateForm({ ...payload, "last_updated_at": lastUpdated }); }
    promise?.then(res => {
      modalDispatch({ type: ModalActions.CHANGE_MODAL_STATE, payload: { state: ModalState.SUBMIT_SUCCESS } });
      getEnv(store).mixpanel.track('FORM_SUBMIT', { form: formId });
    }).catch(err => {
      modalDispatch({ type: ModalActions.CHANGE_MODAL_STATE, payload: { state: ModalState.SUBMIT_ERROR, message: err.response?.data?.errorMessage } });
    })
  };
  const back = () => { goBack(); };
  const closeModal = () => { modalDispatch({ type: ModalActions.CLOSE_MODAL }); };
  const mapping = { [ModalActions.CLOSE_MODAL]: closeModal, [ModalEffects.GO_BACK]: back, [ModalEffects.SUBMIT]: onSubmit };

  return (<div className={classes.mainDiv}>
    <div className={classes.handleContainer}>
      <GenericModal
        isOpen={modalState.isOpen}
        title={modalState.title}
        onClose={closeModal}
        actions={modalsActionsConfig[modalState.state].map(({ type, onClick }) => ({
          type,
          onClick: onClick.effect ? mapping[onClick.effect] : onClick.action ? mapping[onClick.action] : () => { }
        }))}
      >
        {modalState.loading ? (<LoadingSpinner />) : modalState.text}
      </GenericModal>
      {loading ? (<div style={{ height: "100%" }}><LoadingSpinner bgColor="rgb(250, 250, 250)" /> </div>) :
        (<div className={classes.titleContainer}>
          <div className={classes.titlediv}>
            <span className={classes.workStatusdiv}>{title.formDescription}</span>
          </div>
          <div className={classes.titlediv}>
            <span className={classes.workStatusdiv}>{title.unit} — {title.activity}</span>
          </div>
        </div>)
      }
      <div className={classes.handleScrollLayer}>
        {fields.length && Object.keys(state).length ? fields.map(({ id, name, type, prefillData, isMandatory, action, description }) => {
          if (type === "dropdown") {
            const Component: MemoExoticComponent<FunctionComponent<IFormDropdownInputProps>> = transformMap[type].Component;
            return (
              <div key={name} className={classes.fieldData}>
                <div className={action === "R" ? classes.ActivityNameDisabled : classes.ActivityName} style={{ fontWeight: 700, textTransform: "none" }}>
                  {name}
                  {isMandatory && action === "W" &&
                    <span style={{ color: "red", fontWeight: 700 }}>*</span>
                  }
                </div>
                {description ? (<p className={classes.fieldDescription}>
                  {description}
                </p>) : null}
                <Component
                  field={id}
                  dispatch={dispatch}
                  state={state[id]}
                  label={name}
                  options={prefillData}
                  disabled={action === "R"}
                />
              </div>
            );
          }
          else {
            const Component: MemoExoticComponent<FunctionComponent<IGenericInputProps | IImagePickerProps | IDateTimeInputProps>> = transformMap[type].Component;
            return (<div key={name} className={classes.fieldData}>
              <div className={action === "R" ? classes.ActivityNameDisabled : classes.ActivityName} style={{ fontWeight: 700, textTransform: "none" }}>
                {name}
                {isMandatory && action === "W" &&
                  <span style={{ color: "red", fontWeight: 700 }}>*</span>
                }
              </div>
              {description ? (<p className={classes.fieldDescription}>
                {description}
              </p>) : null}
              <Component
                field={id}
                dispatch={dispatch}
                state={state[id]}
                type={type === "datetime" ? "datetime-local" : type}
                label={name}
                disabled={action === "R"}
              />
            </div>
            );
          }
        })
          : null}
      </div>
      {fields.length ? (
        <div style={{ display: "flex", flexDirection: "column", }}>
          <div style={{ flexShrink: 1, display: "flex", padding: "1em" }}>{
            disabled ? !state.isChanged ? (<span>No changes have been made in the form yet.</span>) : (<span style={{ color: "red" }}>You can only submit the form when all mandatory fields are filled.</span>) : null}</div>
          <div className={classes.submitCancelDiv}>
            <Button
              className={classes.submitbutton}
              disabled={disabled}
              onClick={() => { modalDispatch({ type: ModalActions.OPEN_MODAL, payload: { state: ModalState.SUBMIT_OPEN } }); }}
            >
              SUBMIT
            </Button>

            <Button
              className={classes.submitbutton}
              onClick={() => { modalDispatch({ type: ModalActions.OPEN_MODAL, payload: { state: ModalState.CANCEL_OPEN } }); }}>
              CANCEL
            </Button>
          </div>
        </div>) : null}
    </div>
  </div>
  );
};

export default observer(FormDataWritePage);
