import React, { useEffect, useState, useMemo, FunctionComponent } from "react";
import { useLocation, useHistory, useParams } from "react-router";
import moment from "moment";
import { compose, transduce, map, filter, toArray } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_UNIT_INFO, GET_UNIT_INFO_HEADERS, GET_UNIT_ACTIVITY_SNAGS_COUNT } from "../../utils/queries";
import FatTable from "../../components/fatTable/FatTable";
import TableMultiselect from "../../components/fatTable/TableMultiselect";
import TableBoolean from "../../components/fatTable/TableBoolean";
import TableDate from "../../components/fatTable/TableDate";
import Paginate from "../../components/fatTable/Pagination";
import DefaultCells from "../../components/DefaultCells";
import BlockCell from "../../components/BlockCell";
import SnagCell from "../../components/SnagCell";
import StepNameDetails from "../../components/StepNameDetails";
import { useSingleValueURLParam } from "../../utils/hooks";
import LoadingSpinner from "../../components/loadingSkelaton/LoadingSpinner";
import { RenderedDate, StatusWithDelayed, WorkProgressPercentage, FormData } from '../../components/table/Commonfunctions';
import TableSnagInformation from "../../components/unitInfoSummaryComponent/UnitInfoSummaryInformation";
import useStyles from '../../components/fatTable/FatTableStyle'
import { useStore } from '../../models/ProvideModel';
import { useObserver } from "mobx-react-lite";
import { sortingWithPrecedence } from "../../utils/utils";
import ChecklistReportsPopup from "../../components/ChecklistReports/ChecklistReportsPopup";

const __DEFAULT = 100;

interface IQueryFilterParams {
  where: Record<string, any>;
  order_by: { [k: string]: "asc" | "desc" }[];
}
interface IQueryPageParams {
  limit: number;
  offset: number;
}

type IQueryParams = IQueryFilterParams & Partial<IQueryPageParams>;

export interface IColumnOptions {
  id: string;
  name: string;
  filter?: Function;
  Component?: FunctionComponent<{ [K: string]: any; }>;
  Cell?: FunctionComponent;
  print?: boolean;
  value?: string;
  isVisible?: boolean;
  accessor?: string;
  minWidth?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  data?: { id: string; name: string; }[];
  sort?: (a, b) => number;
}

export function useCustomQueryParameters(
  UnitInfoPagecolumns: IColumnOptions[],
  filters: URLSearchParams
) {
  // const { blockId } = useParams();               // Use when block_id is a fk
  const [param, setParam] = useState<IQueryParams>();
  const store = useStore();
  useEffect(() => {
    const sort = filters.getAll("sort");
    const descSort = filters.getAll("sortDesc");
    let variables: IQueryParams = {
      where: { block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } } },
      order_by: descSort.length === 0 && sort.length === 0 ? [{ block_id: "asc" }] : [],
    };
    for (const { id: field, filter } of UnitInfoPagecolumns) {
      if (sort.length > 0 && sort.includes(field)) {
        variables["order_by"].push({ [field]: "asc" });
      }
      if (descSort.length > 0 && descSort.includes(field)) {
        variables["order_by"].push({ [field]: "desc" });
      }
      const value = filters.getAll(field);
      if (value.length > 0) {
        if (filter) {
          variables["where"] = filter === formComplex ?
            {
              ...(variables["where"] || {}),
              ...filter(value[0], store.auth.userId)
            }
            : filter === snagBool ? {
              ...(variables["where"] || {}),
              ...filter.apply(null, value)
            } : {
                ...(variables["where"] || {}),
                [field]: { ...filter.apply(null, value) },
              };
        } else {
          if (value.length === 1) {
            const singleVal = value[0];
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _eq: singleVal },
            };
          } else {
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _in: [...value] },
            };
          }
        }
      }
    }
    setParam({ ...variables });
  }, [filters]);
  return param;
}


function delta(input1: string, input2: string): { _lt: string; _gt: string } {
  if (!(input1 && input2)) {
    throw new Error("date type filters must define a range");
  }
  const dates = [input1, input2].map((d) => moment(d));
  return {
    _lt: moment.max(...dates).toISOString(),
    _gt: moment.min(...dates).toISOString(),
  };
}

function range(input: string): { _gt?: number; _lt?: number } {
  const [clause, value] = input.split(/([0-9]+)/);
  switch (clause) {
    case "lt":
      return { _lt: Number.parseInt(value) };
    case "gt":
    default:
      return { _gt: Number.parseInt(value) };
  }
}

//          Doesn't work because attachment is an empty string instead of null
/* function bool(input: string): { _is_null: boolean } {
 *   return { _is_null: input === "true" };
 * } */

function bool(input: string): { _neq: string; } | { _eq: string; } {
  return input === "false" ? { _eq: "" } : { _neq: "" };
}

function snagBool(input: string): { snag_counts?: {}; _not?: { snag_counts: {} } } {
  return input === "false" ? { _not: { snag_counts: {} } } : { snag_counts: {} };
}

function formComplex(input: string, user: string) {
  switch (input) {
    case 'edit': return { _or: [{ current_user_id: { _eq: user }, form_permission: { _contains: { action: "RW" } } }, { current_user_id: { _eq: user }, form_permission: { _contains: { action: "WRITE" } } }] };
    default: return {};
  }
}

export const UnitInfoPagecolumns: IColumnOptions[] = [
  { id: "block_id", value: "block", accessor: "block.id", name: "Block", Component: TableMultiselect, print: true, Cell: BlockCell, headerObj: "block", minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['block']['title'] },
  { id: "floor_idx", value: "floor_name", name: "Floor", Component: TableMultiselect, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['floor_name'] },
  { id: "precedence", value: "precedence", name: "Precedence", isVisible: false, Component: TableMultiselect, print: false, minWidth: 100, isExcelField: false, excelExport: (elt: any) => elt['precedence'] },
  { id: "activity_type_id", value: "activity_name", name: "Activity Name", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, sort: sortingWithPrecedence, excelExport: (elt: any) => elt['activity_name'] },
  { id: "unit_id", value: "unit_name", name: "Unit Title", Component: TableMultiselect, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_name'] },
  { id: "pour_number", name: "Pour", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['pour_number'] },
  { id: "unit_type_id", value: "unit_type_title", name: "Unit Type", Component: TableMultiselect, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_type_title'] },
  { id: "phase_id", name: "Phase", value: "phase_title", Component: TableMultiselect, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['phase_title'] },
  { id: "form_data", value: "form_data", name: "Data", Cell: FormData, filter: formComplex, print: true, headerObj: "form_data", minWidth: 80, isExcelField: false },
  { id: "space_type_id", name: "Space Type", value: "space_type_title", Component: TableMultiselect, isExcelField: true, excelExport: (elt: any) => elt['space_type_title'] },
  { id: "progress", name: "Work Step %", minWidth: 90, Cell: WorkProgressPercentage, print: true, isExcelField: true, excelExport: (elt: any) => elt['progress'] },
  { id: "current_step", name: "Step", Cell: StepNameDetails, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_step'] },
  { id: "status_desc", name: "Activity Status", Component: TableMultiselect, Cell: StatusWithDelayed, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['status'] === '1_planned' ? 'Not Started' : elt['status'] === '3_active' ? 'On Going' : elt['status'] === '8_done' ? 'Completed' : 'Error' },
  { id: "current_user_name", name: "Currently With", Component: TableMultiselect, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_user_name'] },
  { id: "snag_counts", value: "snag_counts", accessor: "snag_counts.count_open", name: "Open / Total Snags", print: true, Component: TableBoolean, filter: snagBool, Cell: SnagCell, minWidth: 120, data: [{ id: "true", name: "Units with Snag Counts" }, { id: "false", name: "Units without Snag Counts" }], isExcelField: true, excelExport: (elt: any) => !elt['snag_counts'] ? '-' : `{Open : ${elt['snag_counts']['count_open']} | Closed : ${elt['snag_counts']['count_closed']} | For review : ${elt['snag_counts']['count_for_review']}}` },
  { id: "last_updated", name: "Last Updated", filter: delta, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['last_updated'] ? '-' : moment(elt['last_updated']).format('DD/MM/YYYY') },
  { id: "plan_start", name: "Plan Start", filter: delta, Component: TableDate, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_start'] ? '-' : moment(elt['plan_start']).format('DD/MM/YYYY') },
  { id: "actual_start", name: "Actual Start", filter: delta, Component: TableDate, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['actual_start'] ? '-' : moment(elt['actual_start']).format('DD/MM/YYYY') },
  { id: "plan_end", name: "Plan End", filter: delta, print: true, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_end'] ? '-' : moment(elt['plan_end']).format('DD/MM/YYYY') },
  { id: "actual_end", name: "Actual End", filter: delta, Cell: RenderedDate, Component: TableDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['actual_end'] ? '-' : moment(elt['actual_end']).format('DD/MM/YYYY') },
];
const UnitInfoPage = () => {
  const [data, setData] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<{}>({});
  const [totalCount, setTotal] = useState<number>(0);
  const store = useStore();
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { search, ...location } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { push } = useHistory();
  const params = useCustomQueryParameters(UnitInfoPagecolumns, searchParams);
  const [print] = useSingleValueURLParam("view", "normal", searchParams);
  const [loading, setloading] = useState<boolean>(false)
  const { tower, spaceType, phase }: { tower?: string | undefined, spaceType?: string | undefined, phase?: string | undefined } = useParams();
  const pathParams = useMemo(() => spaceType && phase ? tower ? ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase }, "block_id": { "_eq": tower } }) : ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase } }) : ({}), [tower, spaceType, phase]);
  const classes = useStyles({ print });
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const columnsConfig = useMemo(
    () =>
      transduce(
        UnitInfoPagecolumns,
        compose(
          filter(({ print: printV }) => (print === "print" ? !!printV : true)),
          filter(({ id }) => {
            switch (id) {
              case 'block_id': return !tower;
              case 'space_type_id': return !spaceType;
              case 'phase_id': return !phase;
              default: return true;
            }
          }),
          filter(({ isVisible }) => {
            return isVisible === undefined ? true : isVisible;
          }),
          filter(({ id }) =>
            id === "pour_number" ? phase === "structures" ? true : false : true
          ),
          filter(({ id }) =>
            headersData[id] ? (headersData[id].length > 0 ? true : false) : true
          ),
          map(({ id, name, sort, Component, Cell, isVisible, accessor, headerObj, minWidth, ...rest }) => ({
            name,
            Header:
              Component && print !== "print"
                ? Component
                : name,
            minWidth: minWidth || 150,
            id,
            accessor: accessor || id,
            headerObj,
            Cell: !!Cell ? Cell : DefaultCells,
            data: headersData[id] ? sort ? headersData[id].slice().sort(sort) : headersData[id] : null,
            ...rest
          }))
        ),
        toArray()
      ),
    [headersData, print, tower, phase, spaceType]
  );
  const [offset, setPage] = useSingleValueURLParam<number>(
    "page",
    0,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  const [limit, setSize] = useSingleValueURLParam<number>(
    "pageSize",
    __DEFAULT,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  useEffect(() => {
    if (client.client && store.projectInfo.currentProject?.properties?.length) {
      store.exportTableFilters.changeHeaderDataLoading(true)
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_UNIT_INFO_HEADERS, variables: { where: { block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setHeadersData(
              UnitInfoPagecolumns.reduce(
                (acc, { id, Component }) =>
                  Component
                    ? { ...acc, [id]: data[id] && data[id].length > 0 ? data[id] : null }
                    : acc,
                {}
              )
            );
            setDataLoading(true);
            store.exportTableFilters.changeHeaderDataLoading(false)
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  }, [pathParams]);
  // Issue the query
  useEffect(() => {
    if (client.client && params) {
      setloading(true)
      const variables: IQueryParams =
        print === "print"
          ? params
          : { ...params, limit, offset: offset * limit };
      const query = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery<Array<any>, IQueryParams>({
        query: GET_UNIT_INFO,
        variables: { ...variables, where: { ...variables.where, block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } },
      }).subscribe(({ data, loading, errors }) => {
        if (errors) { console.error(errors); setloading(false); }
        else if (!loading) {
          setData(data["unitInfo"]);
          setTotal(data["meta"]["total"]["count"]);
          setloading(false)
        }
      });
      return () => {
        query.unsubscribe();
      };
    }
  }, [params, offset, limit, print, pathParams]);

  useEffect(() => {
    if (client.client && data.length) {
      const ids: { [K: string]: number; } = {};
      let i = 0, len = data.length;
      for (i; i < len; i++) {
        ids[data[i]['unit_activity_id']] = i;
      }
      const query = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery({
        query: GET_UNIT_ACTIVITY_SNAGS_COUNT,
        variables: { ids: Object.keys(ids) },
      });
      const observable = query.subscribe(({ data: { snagCounts }, errors }) => {
        if (errors) { console.error(errors); }
        else if (snagCounts) {
          var res = [...data];
          let i = 0, len = snagCounts.length;
          for (i; i < len; i++) {
            const { unit_activity_id, ...rest } = snagCounts[i];
            res[ids[unit_activity_id]] = { ...res[ids[unit_activity_id]], snag_counts: { ...rest } };
          }
          setData(() => [...res]);
        }
      });
      return () => {
        observable.unsubscribe();
      };
    }
  }, [data.length]);

  const mobilefullScreenMode = (fullScreenMode) => {
    return !fullScreenMode ? classes.tablePanelSm : classes.tablePanelFs
  }

  useEffect(() => {
    store.downloadpdf.setReportName("UNIT INFO")
    store.downloadpdf.setTotalCount(totalCount)
    store.exportTableFilters.setZeroRows(data.length <= 0 ? true : false)
    store.exportTableFilters.setColumns(UnitInfoPagecolumns)
    store.exportTableFilters.setParams(JSON.stringify(params))
    store.exportTableFilters.setPathParams(JSON.stringify(pathParams))
    store.exportTableFilters.setHeadersDataCount(Object.keys(headersData).length ? Object.keys(headersData).length : 0)
  }, [totalCount, params, pathParams, Object.keys(headersData).length])

  useEffect(() => {
    store.exportTableFilters.setColumnConfig(columnsConfig)
  }, [dataLoading])

  useEffect(() => () => {
    store.exportTableFilters.clearTableFiltersData()
  }, [])

  return useObserver(() => (
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          // overflow: "scroll",
          flexGrow: 1,
          flexShrink: 1,
          // height:"70vh"
        }}
      >
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.tablePanel : mobilefullScreenMode(store.responsiveUtils.fullScreenMode)}>
          <TableSnagInformation pathParams={pathParams} params={params} loading={loading} />
          <ChecklistReportsPopup />
          {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={(print !== "print") ? "80%" : "100%"} /> : <FatTable columns={columnsConfig} data={data} print={print} />}
          {print !== "print" && (
            <Paginate
              totalCount={totalCount}
              page={offset}
              size={limit}
              setPage={setPage}
              setSize={setSize}
            />
          )}
        </div>
      </div>
    </div>
  ));
};

export default UnitInfoPage;
