import React, { useState, ReactElement, memo, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router';
import { Typography } from '@material-ui/core';
import PhaseType from '../phaseType/PhaseType';
import TowerReports from "../reports/towerReports/TowerReports";
import AdditionalReports from "../reports/additionalReports/AdditionalReports";
import useStyles from './MenuPanelStyles';
import PropertyTool from '../propertyTool/PropertyTool';
import { useStore } from '../../../models/ProvideModel';
import arrowIcon from '../../../assets/images/menuNavigation/Arrow-default.svg';
import { Button } from '../../button/Button';
import { ModalProps, Modal, ModalOverlay, ModalContent, ModalBody, useModal } from '../../../components/modal';
import { MenuListModal } from '../../../containers/menuListModal';
import CloseDefault from '../../../assets/images/menuNavigation/Close Default.svg'
import CloseDisable from '../../../assets/images/menuNavigation/Arrow-Circle-Disabled.svg'
import { useObserver } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';


export interface IPhases {
  phase_id: string,
  tbl_space_type_models: Array<object>
}

export type MenuListItems = {
  label: string,
  key: string
}

export interface IitemRefsDict {
  [key: string]: HTMLElement
}

const getMenuList = (phases: any[], propTools?: boolean) =>
  !!propTools ?
    phases.map(p => ({ label: p.phase, key: p.phase }))
      .concat({ label: "PROPERTY TOOLS", key: "property_tools" })
    :
    phases.map(p => ({ label: p.phase, key: p.phase }));


function MenuPanel({ phases }: { phases?: any[]; }) {
  const [flyMenu, setflyMenu] = useState<ReactElement | null>(null);
  const classes = useStyles();
  const { push, goBack } = useHistory();
  const store = useStore();
  const location = useLocation();
  const { search, pathname } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const [rowIndex, setrowIndex] = useState<number | null>(null);
  const [colIndex, setcolIndex] = useState<number | null>(null);
  const [hover, setHover] = useState<boolean>(false);

  const routeHandler = (meta: object, spaceType: string, phase: string, title?: any) => () => {
    document?.getElementById(`${phase}_${spaceType}_${title}`)?.removeAttribute("href");
    push(store.params.toPath({ ...meta, spaceType, phase }));
  }
  const [modalState, setModalState] = useState(false)

  const itemRefs: IitemRefsDict = {};

  const [activeMenuItem, setActiveMenuItem] = useState<string | undefined>(undefined);

  const openHandler = (el: ReactElement | null, row?: number, col?: number) => {
    if ((row !== undefined) && (col !== undefined)) {
      setrowIndex(row);
      setcolIndex(col);
      if (row === rowIndex && col === colIndex && flyMenu !== null) {
        setflyMenu(null)
        searchParam.delete("popup_menu");
        searchParam.append("popup_menu", "true");
        push({ ...location, search: searchParam.toString() });
        // setModalState(!modalState);
        // if (store.filters.open == true) {
        //   push(`/menu?open=${store.filters.open}`);
        // } else {
        //   push(`/menu`);
        // }
      }
      else { setflyMenu(_ => el); }
    }
  }
  useEffect(() => {
    if (location.search.match('popup_menu=true')) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (phases && phases.length > 0) {
      setActiveMenuItem(phases[0].phase);
    } else {
      setActiveMenuItem(undefined);
    }

  }, [phases]);


  const collectRefrence = (label: string, re: HTMLElement | null) => {
    if (re) itemRefs[label] = re;
  };

  /**
   * Oops! It has some side effects. :(
   * @param phase
   */
  const onModalClose = (data?: any) => {
    setModalState(!modalState);
    if (data) {
      itemRefs[data.phase.key].scrollIntoView();
      setActiveMenuItem(data.activeItem);
    }
  }

  const defaultModalProps: ModalProps = {
    isOpen: modalState,
    closeOnOverlayClick: true
  }

  useModal(defaultModalProps);

  return useObserver(() => phases ? (
    <div className={classes.root}>
      {/* <Fade left cascade> */}
      {(store.responsiveUtils.currentViewport.isXsOrSm) && (
        <div>
          <div className={store.filters.open ? classes.modalContainer : classes.modalContainerNoFilter} >
            <Button
              label="Menu"
              primary
              floating
              size="small"
              onClick={() => setModalState(!modalState)}
            />
          </div>
          <Modal
            isOpen={modalState}
            isCentered={defaultModalProps.isCentered}
            onClose={() => onModalClose()}
            closeOnOverlayClick={defaultModalProps.closeOnOverlayClick}
            position={{ bottom: 90, right: 32 }}
          >
            <ModalOverlay>
              <ModalContent>
                <ModalBody >
                  <MenuListModal
                    items={getMenuList(phases, store.userReportDetails.shouldRenderPropTool)}
                    activeItem={activeMenuItem}
                    onItemClicked={(data) => onModalClose(data)}
                  />
                </ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
        </div>
      )}
      <div style={{ zIndex: 1 }}>
        {phases.map(({ phase: phase_id, spaceTypes }: any, row: number) => {
          return (
            <div key={phase_id} style={{ margin: store.responsiveUtils.currentViewport.isLg ? "0 0 32px 0" : "0 0 14px 0" }}>
              <div className={store.responsiveUtils.currentViewport.isLg ? classes.phase : classes.phaseSm} >
                <div ref={(refrence) => collectRefrence(phase_id, refrence)}>{phase_id.toUpperCase()}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {spaceTypes?.map(
                  (
                    { spaceType, report, spaceTypeName },
                    col: number
                  ) =>
                    <PhaseType
                      name={spaceType}
                      actualName={spaceTypeName}
                      key={col}
                      phase={phase_id}
                      openHandler={openHandler}
                      row={row}
                      selected={((col === colIndex) && (row === rowIndex) && (flyMenu !== null))}
                      col={col}
                      overview={report.find(({ meta }) => meta.section === 'dashboard' && meta.screen === 'landing')?.title}
                    >
                      {report.some(({ meta }) => meta.screen === 'fullscreen') &&
                        <TowerReports >
                          {report.reduce((acc: ReactElement[], { title, meta }) => meta.screen === 'fullscreen' ? [...acc, (
                            <Typography
                              key={`${phase_id}_${spaceType}_${title}`} >
                              <a className={classes.name} key={`${phase_id}_${spaceType}_${title}`} id={`${phase_id}_${spaceType}_${title}`}
                                href={`${window.location.href.split('/', 3).join('/')}${store.params.toPath({ ...getSnapshot(store.params), section: meta.section, screen: meta.screen, graphType: meta.graphType, phase: phase_id, spaceType: spaceType })}`}
                                target="popup"
                                onClick={routeHandler(meta, spaceType, phase_id, title)}
                                style={{ textDecoration: "none" }}
                              >
                                <span>Detailed - {title}</span>
                                <img className={classes.arrowIcon} id={"arrowIcon"} src={arrowIcon} alt="arrow" /></a>
                            </Typography>
                          )] : acc, [])}
                        </TowerReports>
                      }
                      {report.some(({ meta }) => !(meta.screen === 'fullscreen' || (meta.screen === 'landing' && meta.section === 'dashboard'))) &&
                        <AdditionalReports >
                          {report.reduce((acc: ReactElement[], { title, meta }) => meta.screen !== 'fullscreen' ? meta.screen === 'landing' && meta.section === 'dashboard' ? acc : [...acc, (
                            <Typography key={`${phase_id}_${spaceType}_${title}`}  >
                              <a className={classes.name} id={`${phase_id}_${spaceType}_${title}`}
                                href={`${window.location.href.split('/', 3).join('/')}${store.params.toPath({ ...getSnapshot(store.params), section: meta.section, screen: meta.screen, graphType: meta.graphType, phase: phase_id, spaceType: spaceType })}`}
                                target="popup"
                                onClick={routeHandler(meta, spaceType, phase_id, title)}
                                style={{ textDecoration: "none" }}>
                                <span>{title}</span>
                                <img className={classes.arrowIcon} id={"arrowIcon"} src={arrowIcon} alt="arrow" /></a>
                            </Typography>
                          )] : acc, [])}
                        </AdditionalReports>
                      }
                    </PhaseType>
                )}
              </div>
              {(row === rowIndex) && flyMenu}
            </div>
          );
        })}
        {store.userReportDetails.shouldRenderPropTool && <div ref={(refrence) => collectRefrence('property_tools', refrence)}> <PropertyTool /> </div>}
      </div>
      {store.filters.open ?
        <div style={{ marginRight: "2em", display: "flex", position: "absolute", right: 0, cursor: "pointer" }}>
          <div className={classes.changeImage} onClick={goBack}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <div className={classes.backDiv}><span className={classes.backBtn}>Close</span></div>
            <img src={hover ? CloseDisable : CloseDefault}></img>
          </div>
        </div>
        : null}
    </div >
  ) : null);
}

export default memo(MenuPanel)
